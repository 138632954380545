import { NgModule } from '@angular/core';
import { ImportToolsPage } from './pages/import-tools/import-tools.page';
import { DeveloperRoutingModule } from './developer-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { ImportToolsWibSftpComponent } from './components/import-tools-wib-sftp/import-tools-wib-sftp.component';
import { ImportToolsSftpComponent } from './components/import-tools-sftp/import-tools-sftp.component';
import { ImportResponseComponent } from './components/import-tools-sftp/import-response/import-response.component';



@NgModule({
  declarations: [
    ImportToolsPage,
    ImportToolsWibSftpComponent,
    ImportToolsSftpComponent,
    ImportResponseComponent
  ],
  imports: [
    DeveloperRoutingModule,
    SharedModule,
  ]
})
export class DeveloperModule { }
