<div class="text-center" class="connect">
  <div
    class="connect-button"
    [ngClass]="{ 'clickable-item': !connected }"
    (click)="handleConnect()"
  >
    <mat-icon class="icon" matSuffix svgIcon="lock"></mat-icon>
    {{connected ? connectedText : connectText}}
    <div
      class="status"
      [ngClass]="{
        active: connected,
        inactive: !connected
      }"
      [matTooltip]="connected ? 'Connected' : 'Not connected'"
    ></div>
  </div>
  <div *ngIf="connected" class="disconnect">
    <a (click)="disconnect.emit()">{{disconnectText}}</a>
  </div>
</div>