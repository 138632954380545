<app-container [authenticationRequired]="false">
  <app-content-block cssStyle="form">

    <div *ngIf="!submitSuccess">
      <form action=""> <!-- Wrapped in form to inherit styling. -->
        <h2>Account Not Verified</h2>
        <p>
          It looks like you're trying to log in with an account that hasn't been verified yet.
          Please check your email for a verification link.
        </p>
        <p>
          If the link has expired or you didn't receive it, click the
          button below to resend the verification email.
        </p>
        <div *ngIf="!isSubmitting">
          <button mat-flat-button color="primary" (click)="sendVerificationEmail()">Resend verification email</button>
        </div>
        <div *ngIf="isSubmitting">Submitting...</div>
      </form>
    </div>

    <div *ngIf="submitSuccess">
      <p>
        A new verification link has been sent to {{currentUser?.email}}. Please check your email to activate your
        account. You can close this window.
      </p>
    </div>

  </app-content-block>
</app-container>
