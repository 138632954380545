<app-container>

  <app-content-block cssStyle="form">

    <form [formGroup]="form">

      <h1>Create a new company</h1>

      <app-form [form]="form"></app-form>

      <div class="buttons">
        <button mat-flat-button color="primary" (click)="submit()">Create</button>
        <button mat-stroked-button [routerLink]="['/companies']">Cancel</button>
      </div>

    </form>

  </app-content-block>

</app-container>