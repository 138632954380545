<table>
  <tr>
    <th>Status</th>
    <th>Timestamp</th>
    <th>Message</th>
    <th>Details</th>
  </tr>
  <ng-container *ngFor="let logLine of logLines">
    <tbody app-log-line [logLine]="logLine"></tbody>
  </ng-container>
</table>