import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth.service';
import { IconService } from '../../../../shared/services/icon.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent {

  @Input()
  public form!: FormGroup;

  public importExportGroups = [
    '09:00',
    '09:10',
    '09:20',
  ];

  constructor(
    private iconService: IconService,
    private authService: AuthService,
  ) {
    this.iconService.loadIcons([
      'company',
      'dialpad',
      'list_alt',
    ]);
  }

  public isAdmin(): boolean {
    return this.authService.isAdmin();
  }

}
