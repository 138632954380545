import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreatePage } from './pages/create/create.page';
import { DetailsPage } from './pages/details/details.page';
import { EditPage } from './pages/edit/edit.page';
import { ExportLogPage } from './pages/export-log/export-log.page';
import { ExportSettingsPage } from './pages/export-settings/export-settings.page';
import { ImportLogPage } from './pages/import-log/import-log.page';
import { ImportSettingsPage } from './pages/import-settings/import-settings.page';
import { ListPage } from './pages/list/list.page';
import { StatementsPage } from './pages/statements/statements.page';
import { AuthGuard } from '../../shared/guards/auth';
import { ProfileCompletionGuard } from 'src/app/shared/guards/profile-complete';
import { MFAEnrollmentGuard } from 'src/app/shared/guards/mfa-enrollment';

const routes: Routes = [
  {
    path: 'bank-accounts',
    canActivate:[AuthGuard, MFAEnrollmentGuard, ProfileCompletionGuard],
    children: [
      {
        path: '',
        component: ListPage,
      },
      {
        path: 'create/:companyId',
        component: CreatePage,
      },
      {
        path: ':bankAccountId',
        component: DetailsPage,
      },
      {
        path: ':bankAccountId/edit',
        component: EditPage,
      },
      {
        path: ':bankAccountId/import-logs/:importLogId',
        component: ImportLogPage,
      },
      {
        path: ':bankAccountId/export-logs/:exportLogId',
        component: ExportLogPage,
      },
      {
        path: ':bankAccountId/statements',
        component: StatementsPage,
      },
      {
        path: ':bankAccountId/import-settings',
        component: ImportSettingsPage,
      },
      {
        path: ':bankAccountId/export-settings',
        component: ExportSettingsPage,
      },
    ],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class BankAccountRoutingModule {}
