import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import firebase from 'firebase/app';
import 'firebase/auth';

import { getErrorMessage } from 'src/app/shared/utils/error-message';
import { environment } from 'src/environments/environment';
import { RecoveryService } from 'src/app/shared/services/recovery-service';
import { ConfirmDialogComponent, IConfirmData } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RecaptchaService } from 'src/app/shared/services/recaptcha.service';

@Component({
  selector: 'app-enroll-mfa',
  templateUrl: './enroll-mfa.page.html',
  styleUrls: ['./enroll-mfa.page.scss'],
})
export class EnrollMfaPage implements OnInit, AfterViewInit, OnDestroy {
  public form: FormGroup;
  public verificationForm: FormGroup;
  public verificationInProgress = false;
  public errorMessage = '';
  public phoneNumber = '';
  public recoveryCode: string | null = null;

  private recaptchaVerifier?: firebase.auth.RecaptchaVerifier;
  private verificationId?: string;

  private dialogSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private recoveryService: RecoveryService,
    private dialog: MatDialog,
    private recaptchaService: RecaptchaService,
  ) {
    this.form = new FormGroup({
      phoneNumber: new FormControl('', [Validators.required]),
    });

    this.verificationForm = new FormGroup({
      verificationCode: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.initializeFirebase();
  }

  ngAfterViewInit(): void {
    this.recaptchaService.resetRecaptcha();
  }

  ngOnDestroy(): void {
    this.dialogSubscription?.unsubscribe();
  }

  private initializeFirebase(): void {
    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebase);
    }
  }

  public async startEnrollment(): Promise<void> {
    if (this.form.invalid) {
      return;
    }

    const user = firebase.auth().currentUser;

    if (!user) {
      this.errorMessage = 'No user is currently signed in.';
      return;
    }

    try {
      await user.reload();
      const enrolledFactors = user.multiFactor.enrolledFactors;

      const phoneFactorExists = enrolledFactors.some(
        (factor) => factor.factorId === 'phone'
      );

      if (phoneFactorExists) {
        this.errorMessage = 'You have already enrolled a phone number for two-factor authentication.';
        return;
      }

      const multiFactorSession = await user.multiFactor.getSession();
      this.phoneNumber = this.form.get('phoneNumber')!.value;

      const phoneInfoOptions: firebase.auth.PhoneInfoOptions = {
        phoneNumber: this.phoneNumber,
        session: multiFactorSession,
      };
      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
      this.recaptchaVerifier = this.recaptchaService.getRecaptchaVerifier();
      if (!this.recaptchaVerifier) {
        throw new Error('reCAPTCHA verifier is not initialized.');
      }

      this.verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        this.recaptchaVerifier
      );
      this.errorMessage = '';
      this.verificationInProgress = true;

    } catch (error: any) {
      if (error.code === 'auth/invalid-phone-number') {
        this.errorMessage = 'Invalid phone number. Make sure to include your country code (e.g., +316 for Dutch mobile numbers) in the correct format.';
      } else if (error.code === 'auth/requires-recent-login') {
        this.handleRequiresRecentLogin();
      } else {
        console.error('Error during enrollment:', error);
        this.errorMessage = getErrorMessage(error);
      }
    }
  }

  public async resendCode(): Promise<void> {
    const user = firebase.auth().currentUser;

    if (!user) {
      this.errorMessage = 'No user is currently signed in.';
      return;
    }

    try {
      const multiFactorSession = await user.multiFactor.getSession();
      const phoneNumber = this.phoneNumber;

      if (!phoneNumber) {
        throw new Error('Phone number is not available.');
      }

      const phoneInfoOptions: firebase.auth.PhoneInfoOptions = {
        phoneNumber,
        session: multiFactorSession,
      };
      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

      if (!this.recaptchaVerifier) {
        throw new Error('reCAPTCHA verifier is not initialized.');
      }

      this.recaptchaService.resetRecaptcha();

      this.verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        this.recaptchaVerifier
      );
      this.snackBar.open('Verification code resent.', 'Close', {
        duration: 5000,
        panelClass: ['success-snackbar'],
      });
    } catch (error: any) {
      if (error.code === 'auth/requires-recent-login') {
        this.handleRequiresRecentLogin();
      } else {
        console.error('Error resending verification code:', error);
        this.errorMessage = getErrorMessage(error);
      }
    }
  }

  public async verifyCode(): Promise<void> {
    if (this.verificationForm.invalid || !this.verificationId) {
      return;
    }

    const verificationCode = this.verificationForm.get('verificationCode')!.value;
    const cred = firebase.auth.PhoneAuthProvider.credential(
      this.verificationId,
      verificationCode
    );
    const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
    const user = firebase.auth().currentUser;

    if (!user) {
      this.errorMessage = 'No user is currently signed in.';
      return;
    }

    try {
      await user.multiFactor.enroll(multiFactorAssertion, 'My Phone Number');
      this.verificationInProgress = false;

      this.recoveryService.getBackupCode(user.uid).subscribe(
        (recoveryCode: string) => {
          this.recoveryCode = recoveryCode;
        },
        (error) => {
          console.error('Error fetching recovery code:', error);
          this.errorMessage = getErrorMessage(error);
        }
      );
    } catch (error: any) {
      if (error.code === 'auth/invalid-verification-code') {
        this.errorMessage = 'Invalid verification code.';
      } else {
        console.error('Error during verification:', error);
        this.errorMessage = getErrorMessage(error);
      }
    }
  }

  private handleRequiresRecentLogin(): void {
    this.snackBar.open('Reauthentication required.', 'Dismiss', {
      duration: 15000,
      panelClass: ['notification-snackbar'],
    });
    this.router.navigate(['/reauthenticate'], { queryParams: { action: 'enroll-mfa' } });
  }

  public navigateHome(): void {
    const confirmData: IConfirmData = {
      title: 'Navigate Home',
      message: "Have you saved your backup code? You'll need it to recover your account if you lose access to your phone.",
      confirmButtonText: 'Yes, I have saved my backup code',
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: confirmData,
    });

    this.dialogSubscription = dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.router.navigate(['/']);
      }
    });
  }
}
