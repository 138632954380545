import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ICompany } from '../../../../shared/model/company.model';
import { CompanyService } from '../../../../shared/services/company.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.page.html',
  styleUrls: ['./edit.page.scss']
})
export class EditPage implements OnInit {

  public company$!: Observable<ICompany>;

  public form = new FormGroup({
    name: new FormControl('', Validators.required),
  })

  constructor(
    private companyService: CompanyService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  public ngOnInit(): void {
    const companyId = Number(this.route.snapshot.paramMap.get('companyId'));
    this.company$ = this.companyService.get(companyId).pipe(
      tap(company => this.form.patchValue({ name: company.name })),
    );
  }

  public submit(company: ICompany): void {
    if (this.form.valid) {
      company = this.updateCompany(company);
      this.companyService.update(company).then(() => {
        this.router.navigate(['/companies', company.id]);
      });
    }
  }

  private updateCompany(company: ICompany): ICompany {
    company.name = this.form.get('name')?.value;
    return company;
  }

}
