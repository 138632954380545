<div class="text-center" class="connect">
  <div class="connect-button clickable-item" (click)="connect()">
    <mat-icon class="icon" matSuffix svgIcon="lock"></mat-icon>
    Connect to Twinfield
    <div
      class="status"
      [matTooltip]="(connected) ? 'Connected' : 'Not connected'"
      [ngClass]="{
      active: connected,
      inactive: !connected
    }"></div>
  </div>
</div>

<div *ngIf="!connected" class="cancel text-center">
  <button mat-stroked-button (click)="cancel()">Cancel</button>
  <div class="comment">Don't use Twinfield as my export type</div>
</div>

<form [formGroup]="form" *ngIf="connected">
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Company code</mat-label>
      <select matNativeControl formControlName="companyCode">
        <option value=""></option>
        <option *ngFor="let company of companies$ | async" [value]="company">{{ company }}</option>
      </select>
      <mat-icon matSuffix svgIcon="company"></mat-icon>
    </mat-form-field>
  </div>
  <div class="buttons">
    <button mat-flat-button color="primary" (click)="save()">Save</button>
    <button mat-stroked-button [routerLink]="['/bank-accounts', bankAccount.id]">Cancel</button>
  </div>
</form>
