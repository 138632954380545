<tr>
  <td>
    <app-status [status]="logLine.status"></app-status>
  </td>
  <td>{{ logLine.timestamp }}</td>
  <td>{{ logLine.message }}</td>
  <td><span *ngIf="logLine.info" (click)="toggleShowInfo()">Click</span></td>
</tr>
<tr *ngIf="showInfo">
  <td colspan="4">
    <app-log-line-info [logLine]="logLine"></app-log-line-info>
  </td>
</tr>