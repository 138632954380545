<div [formGroup]="formGroup">

  <!-- <div>
    <mat-form-field appearance="outline">
      <mat-label>Hostname</mat-label>
      <input formControlName="host" matInput placeholder="Hostname">
      <mat-icon matSuffix svgIcon="dns"></mat-icon>
    </mat-form-field>
  </div> -->
  <input formControlName="host" type="hidden">

  <!--
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Port</mat-label>
      <input formControlName="port" matInput placeholder="Port">
      <mat-icon matSuffix svgIcon="cable"></mat-icon>
    </mat-form-field>
  </div> -->
  <input formControlName="port" type="hidden">

  <div>
    <mat-form-field appearance="outline">
      <mat-label>Username</mat-label>
      <input formControlName="username" matInput placeholder="Username">
      <mat-icon matSuffix svgIcon="account"></mat-icon>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input formControlName="password" type="password" matInput placeholder="Password">
      <mat-icon matSuffix svgIcon="lock"></mat-icon>
    </mat-form-field>
  </div>

  <div
    *ngIf="!isLoading"
    (click)="testConnection()"
    class="test-connection-button clickable-item"
    [ngClass]="{
      disabled: !canCheckConnection
    }"
  >
    Test SFTP connection
  </div>

  <app-spinner *ngIf="isLoading">
    Testing SFTP connection
  </app-spinner>

  <ng-container *ngIf="connection">
    <div *ngIf="connection.status === 'success'" class="alert alert-success">SFTP connection successfully setup</div>
    <div *ngIf="connection.status === 'error'" class="alert alert-error">Error setting up SFTP connection: {{ connection.message }}</div>
  </ng-container>
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Folder</mat-label>
      <input *ngIf="!connection" formControlName="folder" matInput placeholder="Folder">
      <select *ngIf="connection" matNativeControl formControlName="folder">
        <option value=""></option>
        <option *ngFor="let folder of connection.folders" [value]="folder">{{ folder }}</option>
      </select>
      <mat-icon matSuffix svgIcon="folder"></mat-icon>
    </mat-form-field>
  </div>

</div>
