import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IBankAccount } from '../../../../shared/model/bank-account.model';
import { BankAccountService } from '../../../../shared/services/bank-account.service';
import { IconService } from '../../../../shared/services/icon.service';
import { MasterDataService } from '../../../../shared/services/master-data.service';

@Component({
  selector: 'app-import-settings',
  templateUrl: './import-settings.page.html',
  styleUrls: ['./import-settings.page.scss']
})
export class ImportSettingsPage implements OnInit {

  public importTypes$ = this.masterDataService.getImportTypes();

  public bankAccount!: IBankAccount;

  constructor(
    private bankAccountService: BankAccountService,
    private masterDataService: MasterDataService,
    private route: ActivatedRoute,
    private iconService: IconService,
  ) {
    this.iconService.loadIcon('change');
  }

  public ngOnInit(): void {
    const bankAccountId = Number(this.route.snapshot.paramMap.get('bankAccountId'));
    if (bankAccountId) {
      this.bankAccountService.get(bankAccountId).subscribe((bankAccount) => {
        this.bankAccount = bankAccount;
      });
    }
  }

  public setImportType(importTypeCode: string): void {
    this.bankAccount.importType = importTypeCode;
  }

}
