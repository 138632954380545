export const roleMetaData = {
  user: {
    name: 'Authorized user',
  },
  admin: {
    name: 'Authorized admin',
  },
  owner: {
    name: 'Owner',
  },
}
