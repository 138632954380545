import { Component } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { MatSnackBar } from "@angular/material/snack-bar";
import { take } from "rxjs/operators";
import { AuthService } from "src/app/shared/services/auth.service";
import { actionCodeSettings } from "src/app/shared/utils/action-code-settings";

@Component({
  selector: 'app-send-email-verification-page',
  templateUrl: './send-email-verification.page.html',
  styleUrls: ['./send-email-verification.page.scss']
})
export class SendEmailVerificationPage {
  isSubmitting: boolean = false;
  submitSuccess: boolean = false;
  currentUser: firebase.default.User | null = null;

  constructor(
    private auth: AngularFireAuth,
    private authService: AuthService,
    private snackBar: MatSnackBar,
  ) {
    this.authService.authState.pipe(take(1)).subscribe((user) => {
      this.currentUser = user;
    });
  }

  async sendVerificationEmail() {
    if (!this.currentUser) {
      this.snackBar.open('No user found. Please return to the login page and try signing in again.', 'Dismiss', {
        duration: 15000,
        panelClass: ['error-snackbar'],
      });
      return;
    }

    this.isSubmitting = true;

    try {
      await this.currentUser.sendEmailVerification(actionCodeSettings);
      await this.auth.signOut();
      this.submitSuccess = true;
    } catch (error) {
      console.error('Error sending verification email:', error);
      this.snackBar.open('Error sending verification email', 'Dismiss', {
        duration: 15000,
        panelClass: ['error-snackbar'],
      });
    } finally {
      this.isSubmitting = false;
    }
  }
}
