import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/shared/services/auth.service';
import { IconService } from 'src/app/shared/services/icon.service';
import { EmailStorageService } from 'src/app/shared/services/email-storage.service';

@Component({
  selector: 'app-logout',
  templateUrl: './password-reset.page.html',
  styleUrls: ['./password-reset.page.scss'],
})
export class PasswordResetPage {
  public sendingPWResetMail: boolean = false;

  public form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  constructor(
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private iconService: IconService,
    private emailStorageService: EmailStorageService,
  ) {
    this.iconService.loadIcons([
      'account',
    ]);
    this.form.get('email')!.setValue(this.emailStorageService.getEmail());
  }

  sendPasswordResetEmail(): void {
    if (!this.form.valid) {
      return;
    }
    this.sendingPWResetMail = true;
    const email: string = this.form.get('email')!.value;

    this.authService.sendPasswordResetEmail(email).then(() => {
      this.snackBar.open('Password reset email sent', 'Dismiss', {
        duration: 15000,
        panelClass: ['success-snackbar']
      });
      this.sendingPWResetMail = false;
    }).catch((error) => {
      this.snackBar.open(error.message, 'Dismiss', {
        duration: 15000,
        panelClass: ['error-snackbar']
      });
      this.sendingPWResetMail = false;
    });
  }
}
