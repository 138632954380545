<header>
  <nav>
    <a [routerLink]="['/']" class="logo">
      <img src="/assets/images/tactly/logo/logo-tactly-147x40.png" alt="Tactly logo" />
    </a>
    <ul>
      <li>
        <a [routerLink]="['/companies']" routerLinkActive="active">Companies</a>
      </li>
      <li>
        <a [routerLink]="['/bank-accounts']" routerLinkActive="active">Bank accounts</a>
      </li>
    </ul>
    <div class="user">
      <ng-container *ngIf="user$ | async as user; else loginButton">
        <ng-container *ngIf="user.emailVerified; else loginButton">
          <app-user-info></app-user-info>
        </ng-container>
      </ng-container>

      <ng-template #loginButton>
        <button mat-flat-button color="primary" [routerLink]="['/login']">Login</button>
      </ng-template>
    </div>
  </nav>
</header>
