import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { ICompany } from '../../../../shared/model/company.model';
import { CompanyService } from '../../../../shared/services/company.service';
import { UserService } from '../../../../shared/services/user.service';
import { ConfirmDialogComponent, IConfirmData } from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { roleMetaData } from '../../../../shared/model/role-meta-data';
import { AuthService, CompanyUserService, PermissionService } from '../../../../shared/services';
import { CompanyPermission, CompanyUser } from '../../../../shared/model';

@Component({
  selector: 'app-users',
  templateUrl: './users.page.html',
  styleUrls: ['./users.page.scss']
})
export class UsersPage implements OnInit, OnDestroy {

  private companyId!: number;
  public user$ = this.userService.getCurrentUser();
  public company$!: Observable<ICompany>;
  public companyUsers$!: Observable<CompanyUser[]>;
  public canManageCompanyRoles$!: Observable<boolean>;

  public roleMetaData: any = roleMetaData;

  private dialogSubscription: Subscription | undefined;

  constructor(
    private authService: AuthService,
    private permissionService: PermissionService,
    private userService: UserService,
    private companyService: CompanyService,
    private companyUserService: CompanyUserService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) { }

  public ngOnInit(): void {
    this.companyId = Number(this.route.snapshot.paramMap.get('companyId'));
    this.company$ = this.companyService.get(this.companyId);
    this.companyUsers$ = this.companyUserService.getByCompanyId(Number(this.companyId));
    this.canManageCompanyRoles$ = this.permissionService.hasCompanyPermission(this.companyId, CompanyPermission.ROLES);
  }

  public ngOnDestroy(): void {
    this.dialogSubscription?.unsubscribe();
  }

  public showDeleteDialog(companyUser: CompanyUser): void {
    const data: IConfirmData = {
      title: `Delete permission for "${companyUser.name}"?`,
      message: 'This will remove the permissions for this user on this company and cannot be undone.'
    };
    const dialog = this.dialog.open(ConfirmDialogComponent, { data });
    this.dialogSubscription = dialog.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.delete(companyUser).then(() => {
          this.authService.authState.subscribe({
            next: (user) => {
              if (user && user.uid === companyUser.firebaseId) {
                this.router.navigate(['/companies']);
              } else {
                // Reload the list of users. There should be a nicer way to do this
                this.companyUsers$ = this.companyUserService.getByCompanyId(Number(this.companyId));
              }
            },
            error: (error) => {
              console.error('Error fetching auth state:', error);
            }
          });
        });
      }
    });
  }

  public async delete(companyUser: CompanyUser): Promise<void> {
    if (this.companyId) {
      await this.companyUserService.delete(companyUser.companyId, companyUser.userId);
    }
  }

}

