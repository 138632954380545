<div
  class="background"
  [ngClass]="{
    'primary': cssStyle === 'primary',
    'secondary': cssStyle === 'secondary',
    'white': cssStyle === 'white',
    'light': cssStyle === 'light',
    'form': cssStyle === 'form',
    'padding': hasPadding
  }"
>
  <div
    [ngClass]="{
      'max-width': hasMaxWidth
    }"
  >
    <ng-content></ng-content>
  </div>
</div>