import { Component, Input } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { IBankAccount } from '../../../../shared/model/bank-account.model';
import { IconService } from '../../../../shared/services/icon.service';
import { ConnectionService } from '../../services/connection.service';

@Component({
  selector: 'app-connection',
  templateUrl: './connection.component.html',
  styleUrls: ['./connection.component.scss']
})
export class ConnectionComponent {

  @Input()
  public bankAccount!: IBankAccount;

  @Input()
  public canEditBankAccount!: boolean;

  constructor(
    private connectionService: ConnectionService,
    private iconService: IconService,
  ) {
    this.iconService.loadIcons([
      'bug',
      'play',
    ]);
  }

  public runSingleImport(): void {
    this.connectionService.singleImport(this.bankAccount.id).subscribe();
  }

  public runSingleImportExport(): void {
    this.connectionService.singleImport(this.bankAccount.id).pipe(
      switchMap(() => this.connectionService.singleExport(this.bankAccount.id)),
    ).subscribe();
  }

  public runSingleExport(): void {
    this.connectionService.singleExport(this.bankAccount.id).subscribe();
  }

}
