<app-content-block cssStyle="secondary">
  <div class="footer">
    <div class="banner">
      <h2>Get started today</h2>
      <button mat-flat-button color="primary">More information</button>
    </div>
    <div class="list">
      <h4>Products</h4>
      <ul>
        <li>
          <a [routerLink]="['/']">Bank connection</a>
        </li>
        <li>
          <a [routerLink]="['/']">Transactions</a>
        </li>
      </ul>
    </div>
    <div class="list">
      <h4>Developers</h4>
      <ul>
        <li>
          <a [routerLink]="['/']">Get started</a>
        </li>
        <li>
          <a [routerLink]="['/']">API documentation</a>
        </li>
        <li>
          <a [routerLink]="['/']">Developer portal</a>
        </li>
      </ul>
    </div>
    <div class="list">
      <h4>About us</h4>
      <ul>
        <li>
          <a [routerLink]="['/']">Company</a>
        </li>
        <li>
          <a [routerLink]="['/privacy-policy']">Privacy policy</a>
        </li>
        <li>
          <a [routerLink]="['/end-user-license-agreement']">EULA</a>
        </li>
      </ul>
    </div>
    <div class="list">
      <h4>Help center</h4>
      <ul>
        <li>
          <a [routerLink]="['/']">FAQ</a>
        </li>
        <li>
          <a [routerLink]="['/']">Support</a>
        </li>
        <li>
          <a [routerLink]="['/']">Contact</a>
        </li>
      </ul>
    </div>
  </div>
</app-content-block>