import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ImportToolsPage } from './pages/import-tools/import-tools.page';

const routes: Routes = [
  {
    path: 'developer/import-tools/:bankAccountId',
    component: ImportToolsPage,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class DeveloperRoutingModule { }
