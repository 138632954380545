<app-container>
    <app-content-block cssStyle="form">
        <app-user-form 
            [user] = "user"
            [formTitle]="'Profile'"
            [formButtonText]="'Update Profile'" 
            [isSubmitting]="isUpdating"
            [submitSuccess]="updateSuccess"
            [submitError]="updateError"
            (formSubmitted)="updateProfile($event)"
        >
        </app-user-form>
    </app-content-block>
</app-container>