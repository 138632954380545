<app-container>
  <app-content-block cssStyle="white">
    <ng-container *ngIf="bankAccount$ | async as bankAccount">
      <div *ngIf="exportLog$ | async as exportLog">
        <app-export-log [bankAccount]="bankAccount" [exportLog]="exportLog"></app-export-log>
      </div>
      <ng-container *ngIf="logLines$ | async as logLines">
        <app-log-lines [logLines]="logLines"></app-log-lines>
      </ng-container>
      <div class="text-center">
        <button
        mat-flat-button
        color="primary"
        [routerLink]="['/bank-accounts', bankAccount.id]"
        >
          Return to {{ bankAccount.name }}
        </button>
      </div>
    </ng-container>
  </app-content-block>
</app-container>
