import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { IBankAccount, IBankAccountWithoutID } from '../../../../shared/model/bank-account.model';
import { ICompany } from '../../../../shared/model/company.model';
import { ILogStatusEnum } from '../../../../shared/model/log.model';
import { BankAccountService } from '../../../../shared/services/bank-account.service';
import { CompanyService } from '../../../../shared/services/company.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.page.html',
  styleUrls: ['./create.page.scss']
})
export class CreatePage implements OnInit {

  public company$!: Observable<ICompany>;

  public form = new FormGroup({
    name: new FormControl('', Validators.required),
    account: new FormControl('', Validators.required),
    importExportGroup: new FormControl('09:00', Validators.required),
  });

  constructor(
    private companyService: CompanyService,
    private bankAccountService: BankAccountService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  public ngOnInit(): void {
    const companyId = this.getCompanyId();
    this.company$ = this.companyService.get(companyId);
  }

  public submit(): void {

    this.form.get('name')?.markAsTouched();
    this.form.get('account')?.markAsTouched();

    if (this.form.valid) {
      const bankAccount = this.parseBankAccount();
      this.bankAccountService.create(bankAccount).then(() => {
        this.router.navigate(['/companies', bankAccount.companyId]);
      })
    }
  }

  private parseBankAccount(): IBankAccountWithoutID {
    return {
      companyId: this.getCompanyId(),
      name: this.form.get('name')?.value,
      account: this.form.get('account')?.value,
      active: false,
      importType: '',
      exportType: '',
      importExportGroup: this.form.get('importExportGroup')?.value,
      lastImportStatus: ILogStatusEnum.NONE,
      lastExportStatus: ILogStatusEnum.NONE,
      deleted: false,
    };
  }

  private getCompanyId(): number {
    const companyId = Number(this.route.snapshot.paramMap.get('companyId'));
    return (companyId) ? companyId : 0;
  }

}
