<div class="exportType" *ngIf="bankAccount.exportType; else selectExportType">
  <div class="select clickable-item" [routerLink]="['/bank-accounts', bankAccount.id, 'export-settings']">
    <img [src]="imageSrc" />
  </div>
  <app-export-logs [bankAccountId]="bankAccount.id"></app-export-logs>
</div>

<ng-template #selectExportType>
  <div class="select clickable-item" [routerLink]="['/bank-accounts', bankAccount.id, 'export-settings']">
    <h3>Where can we<br />export your<br /> transactions to?</h3>
  </div>
</ng-template>
