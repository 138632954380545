import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IImportSettingsCmbSftp } from './import-settings-cmb-sftp.model';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ImportSettingsCmbSftpService {

  constructor(
    private http: HttpClient,
  ) { }

  public get(bankAccountId: number): Observable<IImportSettingsCmbSftp> {
    const url = `${environment.api}/v2/bank-accounts/${bankAccountId}/import-settings/cmb-sftp`;
    return this.http.get<IImportSettingsCmbSftp>(url);
  }

  public save(bankAccountId: number, settings: IImportSettingsCmbSftp): Promise<void> {
    const url = `${environment.api}/v2/bank-accounts/${bankAccountId}/import-settings/cmb-sftp`;
    return this.http.post<void>(url, settings).toPromise();
  }

}
