<app-content-block cssStyle="form">
  <!-- Show login form only if verificationId is not set -->
  <form *ngIf="!verificationId && !showRecoveryForm" [formGroup]="form" (ngSubmit)="login()">
    <h2>Log In</h2>
    <mat-error *ngIf="loginInvalid">
      Incorrect email address and/or password
    </mat-error>
    <div>
      <mat-form-field class="full-width-input" appearance="outline">
        <mat-label>Email address</mat-label>
        <input matInput placeholder="Email address" formControlName="email" required>
        <mat-icon matSuffix svgIcon="account"></mat-icon>
        <mat-error>
          Please provide a valid email address
        </mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="full-width-input" appearance="outline">
        <mat-label>Password</mat-label>
        <input matInput type="password" placeholder="Password" formControlName="password" required>
        <mat-icon matSuffix svgIcon="lock"></mat-icon>
        <mat-error>
          Please provide a valid password
        </mat-error>
      </mat-form-field>
    </div>
    <button mat-flat-button color="primary">Login</button><br>
    <div class="account-link-div">
      <ul>
        <li><a [routerLink]="['/signup']" routerLinkActive="active">Create Account</a></li>
        <li><button class="button-link" (click)="onForgotPassword()">Forgot Password?</button></li>
      </ul>
    </div>
  </form>

  <!-- Show verification code input only if verificationId is set -->
  <form *ngIf="verificationId && !showRecoveryForm" [formGroup]="verificationForm" (ngSubmit)="verifyCode()">
    <h2>Multi-Factor Authentication</h2>
    <p>A verification code has been sent to your phone. Please enter it below:</p>
    <mat-error *ngIf="verificationInvalid">
      Incorrect verification code
    </mat-error>
    <mat-form-field class="full-width-input" appearance="outline">
      <mat-label>Verification Code</mat-label>
      <input matInput placeholder="Verification Code" formControlName="verificationCode">
    </mat-form-field>
    <button mat-flat-button color="primary">Verify Code</button>
    <div class="account-link-div">
      <ul>
        <li><a (click)="resendCode()" class="clickable-link">Resend Verification Code</a></li>
        <li><a (click)="toggleRecoveryForm()" class="clickable-link">Use Recovery Code</a></li>
      </ul>
    </div>
  </form>

  <form *ngIf="showRecoveryForm" [formGroup]="recoveryForm" (ngSubmit)="verifyCodeAndResetMfa()">
    <h2>Enter Recovery Code</h2>
    <p>Enter the recovery code you received when setting up MFA. This will reset MFA and allow you to login again.</p>
    <mat-error>{{recoveryErrorMessage}}</mat-error>
    <mat-form-field class="full-width-input" appearance="outline">
      <mat-label>Recovery Code</mat-label>
      <input matInput placeholder="Enter your recovery code" formControlName="recoveryCode" required>
    </mat-form-field>
    <button mat-flat-button color="primary">Verify Recovery Code</button>
    <div class="account-link-div">
      <ul>
        <li><a (click)="toggleRecoveryForm()" class="clickable-link">Back to Verification Code</a></li>
      </ul>
    </div>
  </form>

</app-content-block>
