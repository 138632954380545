<app-container>

  <app-content-block>

    <ng-container *ngIf="user$ | async as user">
      <ng-container *ngIf="company$ | async as company">

        <h1>User permissions</h1>

        <div *ngIf="companyUsers$ | async as companyUsers">
          <table class="list dark">
            <tr>
              <th>User</th>
              <th>Role</th>
              <th></th>
            </tr>
            <tr *ngFor="let companyUser of companyUsers">
              <td>{{ companyUser.name }}</td>
              <td>{{ roleMetaData[companyUser.role].name }}</td>
              <td>
                <div *ngIf="(canManageCompanyRoles$ | async) || user.id === companyUser.userId">
                  <button mat-button color="warn" class="table-button" (click)="showDeleteDialog(companyUser)">
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="table-bottom-options">
          <button class="create" [routerLink]="['/companies', company.id, 'users', 'invite']">Invite user</button>
        </div>

        <div class="table-bottom-options">
          <button mat-stroked-button [routerLink]="['/companies', company.id]">Return to {{company.name}}</button>
        </div>

      </ng-container>
    </ng-container>

  </app-content-block>

</app-container>
