import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import firebase from 'firebase/app';
import 'firebase/auth';

import { AuthService } from '../../services/auth.service';
import { IconService } from '../../services/icon.service';
import { RecoveryResponse, RecoveryService } from '../../services/recovery-service';

import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { RecaptchaService } from '../../services/recaptcha.service';
import { EmailStorageService } from '../../services/email-storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  private resolver?: firebase.auth.MultiFactorResolver;
  private phoneInfoOptions?: firebase.auth.PhoneInfoOptions;

  public form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', Validators.required),
  });

  public verificationForm: FormGroup = new FormGroup({
    verificationCode: new FormControl('', Validators.required),
  });

  public recoveryForm: FormGroup = new FormGroup({
    recoveryCode: new FormControl('', Validators.required),
  });

  public loginInvalid = false;
  public verificationInvalid = false;
  public showRecoveryForm = false;
  public recoveryErrorMessage = '';
  public sendingPWResetMail = false;

  public verificationId?: string;

  constructor(
    private authService: AuthService,
    private iconService: IconService,
    private recoveryService: RecoveryService,
    private emailStorageService: EmailStorageService,
    private router: Router,
    private snackBar: MatSnackBar,
    private recaptchaService: RecaptchaService,
  ) {
    this.iconService.loadIcons([
      'account',
      'lock',
    ]);
  }

  ngOnInit(): void {
    this.initializeFirebase();
  }

  ngAfterViewInit(): void {
    this.recaptchaService.resetRecaptcha();
  }

  ngOnDestroy(): void {
    //
  }

  private initializeFirebase(): void {
    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebase);
    }
  }

  public async login(): Promise<void> {
    if (this.form.invalid) {
      return;
    }

    const email = this.form.get('email')!.value;
    const password = this.form.get('password')!.value;

    const recaptchaVerifier = this.recaptchaService.getRecaptchaVerifier();
    if (!recaptchaVerifier) {
      console.error('reCAPTCHA verifier is not initialized');
      return;
    }

    try {
      const result = await this.authService.login(email, password, recaptchaVerifier);

      if (result.mfaRequired) {
        this.verificationId = result.verificationId;
        this.resolver = result.resolver;
        this.phoneInfoOptions = result.phoneInfoOptions;
      } else if (!result.success) {
        this.loginInvalid = true;
      }
      // Successful login will be handled by LoginPage
    } catch (error) {
      console.error('Login error:', error);
      this.loginInvalid = true;
    }
  }

  public async verifyCode(): Promise<void> {
    if (this.verificationForm.invalid) {
      return;
    }

    const verificationCode = this.verificationForm.get('verificationCode')!.value;

    if (!this.verificationId || !this.resolver) {
      console.error('Verification information is missing');
      return;
    }

    try {
      await this.authService.verifyCode(this.verificationId, verificationCode, this.resolver);
      // Successful verification will be handled by LoginPage
    } catch (error: any) {
      if (error.code === 'auth/invalid-verification-code') {
        this.verificationInvalid = true;
      } else {
        console.error('MFA verification failed:', error);
      }
    }
  }

  public async resendCode(): Promise<void> {
    if (!this.phoneInfoOptions) {
      console.error('Verification information is missing');
      return;
    }

    const recaptchaVerifier = this.recaptchaService.getRecaptchaVerifier();
    if (!recaptchaVerifier) {
      console.error('reCAPTCHA verifier is not initialized');
      return;
    }

    try {
      this.recaptchaService.resetRecaptcha();
      this.verificationId = await this.authService.resendVerificationCode(
        this.phoneInfoOptions,
        recaptchaVerifier
      );
      this.snackBar.open('Verification code resent.', 'Close', {
        duration: 5000,
        panelClass: ['success-snackbar'],
      });
    } catch (error) {
      console.error('Error resending verification code:', error);
    }
  }

  public toggleRecoveryForm(): void {
    this.showRecoveryForm = !this.showRecoveryForm;
  }

  public verifyCodeAndResetMfa(): void {
    if (this.recoveryForm.invalid) {
      return;
    }

    const recoveryCode = this.recoveryForm.get('recoveryCode')!.value;

    this.recoveryService.verifyCodeAndResetMfa(recoveryCode).pipe(
      catchError((error) => {
        this.recoveryErrorMessage = error?.error?.error || 'An error occurred during MFA reset.';
        return [];
      }),
    ).subscribe((response: RecoveryResponse) => {
      if (response.success) {
        this.snackBar.open('MFA has been reset. Please log in again.', 'Close', {
          duration: 15000,
          panelClass: ['success-snackbar']
        });
        this.resetForms();
      }
    });
  }

  private resetForms(): void {
    this.showRecoveryForm = false;
    this.verificationId = undefined;
    this.resolver = undefined;
    this.form.reset();
    this.verificationForm.reset();
    this.recoveryForm.reset();
  }

  onForgotPassword(): void {
    const email = this.form.get('email')!.value;
    this.emailStorageService.setEmail(email);
    this.router.navigate(['/password-reset']);
  }
}
