import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { IApiResponse } from '../../../../shared/model/api-response.model';
import { ImportLogService } from '../../../../shared/services/import-log.service';
import { IImportResponse } from './import-response.model';
import { ISftpFile } from './sftp-file.model';

@Injectable({
  providedIn: 'root'
})
export class ImportToolsSftpService {

  constructor(
    private http: HttpClient,
    private importLogService: ImportLogService,
  ) { }

  public getFolders(bankAccountId: number): Observable<string[]> {
    const url = `${environment.api}/importSftpFolders?bankAccountId=${bankAccountId}`;
    return this.http.get<IApiResponse<string>>(url).pipe(
      map((response) => {
        if (response.status === 'error') {
          throw new Error(response.message);
        }
        return response.results;
      }),
    );
  }

  public getFiles(bankAccountId: number, folder: string): Observable<ISftpFile[]> {
    const url = `${environment.api}/importSftpFiles?bankAccountId=${bankAccountId}&folder=${folder}`;
    return this.http.get<IApiResponse<ISftpFile>>(url).pipe(
      map((response) => {
        if (response.status === 'error') {
          throw new Error(response.message);
        }
        return response.results;
      }),
      switchMap((files) => {
        if (files.length === 0) {
          return of([]);
        }
        return combineLatest(files.map((file) => this.isImported(bankAccountId, file.name).pipe(
          map((isImported) => ({ ...file, isImported })),
        )));
      }),
    );
  }

  public importFile(bankAccountId: number, filename: string): Observable<IImportResponse> {
    const url = `${environment.api}/importSftpImportFile?bankAccountId=${bankAccountId}&filename=${filename}`;
    return this.http.get<IImportResponse>(url);
  }

  private isImported(bankAccountId: number, filename: string): Observable<boolean> {
    return this.importLogService.getByFilename(bankAccountId, filename).pipe(
      map((logs) => (logs.length > 0)),
    );
  }
}
