import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CompanyUser } from '../model';

type SuccessResponse = {
  success: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CompanyUserService {

  constructor(
    private http: HttpClient,
  ) {}

  public getByCompanyId(companyId: number): Observable<CompanyUser[]> {
    const url = `${environment.api}/v2/companies/${companyId}/users`;
    return this.http.get<CompanyUser[]>(url);
  }

  public invite(companyId: number, email: string, role: string): Observable<SuccessResponse> {
    const data = {
      companyId,
      email,
      role
    };
    const url = `${environment.api}/v2/companies/${companyId}/users/invite`;
    return this.http.post<SuccessResponse>(url, data);
  }

  public async delete(companyId: number, userId: number): Promise<void> {
    const url = `${environment.api}/v2/companies/${companyId}/users/${userId}`;
    await this.http.delete(url).toPromise();
  }

}
