import { CanActivate, Router } from "@angular/router";
import { map, switchMap } from "rxjs/operators";
import firebase from "firebase/app";
import { Injectable } from "@angular/core";
import { combineLatest, Observable, of } from "rxjs";
import { AngularFireAuth } from "@angular/fire/auth";
import { UserService } from "../services/user.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root',
})
export class ProfileCompletionGuard implements CanActivate {

  constructor(
    private authService: AngularFireAuth,
    private userService: UserService,
    private router: Router,
    private snackBar: MatSnackBar,
  ) {}

  canActivate(): Observable<boolean> {
    return this.authService.authState.pipe(
      switchMap(firebaseUser => {
        if (firebaseUser) {
          // Ensure Firebase user state is up to date before checking
          return firebaseUser.reload().then(() => firebaseUser);
        } else {
          return of(null);
        }
      }),
      switchMap(firebaseUser => {
        if (firebaseUser) {
          // Fetch the database user data for additional fields
          return combineLatest([of(firebaseUser), this.userService.getCurrentUser()]);
        } else {
          return of([null, null]);
        }
      }),
      map(([firebaseUser, dbUser]) => {
        if (firebaseUser && firebaseUser.emailVerified && this.isProfileComplete(firebaseUser, dbUser)) {
          return true;
        } else if (firebaseUser && !this.isProfileComplete(firebaseUser, dbUser)) {
          this.snackBar.open('Profile completion is required', 'Dismiss', {
            duration: 15000,
            panelClass: ['notification-snackbar'],
          });
          this.router.navigate(['/profile']);
          return false;
        } else {
          this.router.navigate(['/login']);
          return false;
        }
      })
    );
  }

  private isProfileComplete(firebaseUser: firebase.User, dbUser: any): boolean {
    // Check if Firebase profile is complete (e.g., displayName is set)
    const isFirebaseProfileComplete = !!firebaseUser.displayName;

    // Check if additional database fields are complete (e.g., phoneNumber and country)
    const isDbProfileComplete = !!dbUser?.phoneNumber && dbUser.phoneNumber.length > 0 &&
                                !!dbUser?.country && dbUser.country.length > 0;

    // Profile is complete if both Firebase and database fields are valid
    return isFirebaseProfileComplete && isDbProfileComplete;
  }
}
