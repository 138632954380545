<app-container>

  <app-content-block cssStyle="form">

    <ng-container *ngIf="company$ | async as company">

      <form [formGroup]="form">

        <h1>Create bank account</h1>

        <app-form [form]="form"></app-form>

        <div class="buttons">
          <button mat-flat-button color="primary" (click)="submit()">Create</button>
          <button mat-stroked-button [routerLink]="['/companies', company.id]">Cancel</button>
        </div>

      </form>

    </ng-container>

  </app-content-block>

</app-container>
