import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IImportSettingsWibSftp } from '../../model/import-settings/import-settings-wib-sftp.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImportSettingsWibSftpService {

  constructor(
    private http: HttpClient,
  ) { }

  public get(bankAccountId: number): Observable<IImportSettingsWibSftp> {
    const url = `${environment.api}/v2/bank-accounts/${bankAccountId}/import-settings/wib-sftp`;
    return this.http.get<IImportSettingsWibSftp>(url);
  }

  public save(bankAccountId: number, settings: IImportSettingsWibSftp): Promise<void> {
    const url = `${environment.api}/v2/bank-accounts/${bankAccountId}/import-settings/wib-sftp`;
    return this.http.post<void>(url, settings).toPromise();
  }

}
