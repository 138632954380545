<div [formGroup]="form">

  <div>
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input formControlName="name" matInput placeholder="Bank account name">
      <mat-icon matSuffix svgIcon="company"></mat-icon>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field appearance="outline">
      <mat-label>Account number</mat-label>
      <input formControlName="account" matInput placeholder="Account number">
      <mat-icon matSuffix svgIcon="dialpad"></mat-icon>
    </mat-form-field>
  </div>

  <div *ngIf="isAdmin()">
    <mat-form-field appearance="outline">
      <mat-label>Import/export group</mat-label>
      <select matNativeControl formControlName="importExportGroup">
        <option *ngFor="let importExportGroup of importExportGroups" [value]="importExportGroup">{{ importExportGroup }}</option>
      </select>
      <mat-icon matSuffix svgIcon="list_alt"></mat-icon>
    </mat-form-field>
  </div>

</div>
