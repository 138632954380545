import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IUser } from '../model/user.model';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { actionCodeSettings } from '../utils/action-code-settings';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public getCollection(): string {
    return 'users';
  }

  constructor(
    private auth: AngularFireAuth,
    private http: HttpClient,
    private authService: AuthService
  ) { }

  private get(firebaseId: string): Observable<IUser> {
    return this.http.get<IUser>(
      `${environment.api}/v2/users/${firebaseId}`
    );
  }

  public getCurrentUser(): Observable<IUser | null> {
    return this.auth.authState.pipe(
      switchMap((authState) => {
        if (authState) {
          if(!authState.emailVerified) {
            const unverifiedUser: Partial<IUser> = {
              displayName: authState.displayName ?? '',
              email: authState.email ?? '',
              emailVerified: false,
            };
            const { firstname, lastname } = this.extractFirstLastName(unverifiedUser.displayName ?? '');
            unverifiedUser.firstname = firstname;
            unverifiedUser.lastname = lastname;
            return of(unverifiedUser as IUser);
          }

          return this.get(authState.uid).pipe(
            map((user) => {
              if (!user.firstname || !user.lastname) {
                const { firstname, lastname } = this.extractFirstLastName(user.displayName);
                user.firstname = firstname;
                user.lastname = lastname;
              }
              user.emailVerified = true; //Since we know it is not false if we reach this point.
              return user;
            })
          );
        } else {
          return of(null);
        }
      })
    );
  }

  private extractFirstLastName(displayName: string): { firstname: string, lastname: string } {
    const names = displayName.split(' ');
    return {
      firstname: names[0],
      lastname: names.slice(1).join(' '),
    };
  }

  private update(user: IUser, firebaseId: string): Observable<any> {
    return this.http.put<IUser>(
      `${environment.api}/v2/users/${firebaseId}`,
      user
    );
  }

  public updateCurrentUser(user: IUser): Observable<IUser> {
    return this.auth.authState.pipe(
      switchMap((authState) => {
        if (authState) {
          return this.update(user, authState.uid).pipe(
            map((response) => {
              if (response.token) {
                this.authService.signInWithToken(response.token);
              }
              return response;
            })
          );
        } else {
          return of(user);
        }
      })
    );
  }

  public async signUpAndSendVerificationLink(email: string, password: string): Promise<void> {
    const userCredential = await this.auth.createUserWithEmailAndPassword(email, password);
    const user = userCredential.user;
    if (!user) {
      throw new Error('User is null, cannot send verification email.');
    }
    await user.sendEmailVerification(actionCodeSettings);
    await this.auth.signOut();
  }

}
