<table *ngIf="exportLogs$ | async as exportLogs" class="data no-border">
  <tr>
    <th>Date time</th>
    <th>Status</th>
  </tr>
  <tr
    *ngFor="let exportLog of exportLogs"
    [routerLink]="['/bank-accounts', bankAccountId, 'export-logs', exportLog.id]"
    [ngClass]="{
      error: exportLog.status === 'ERROR'
    }"
  >
    <td>{{ exportLog.datetime | firestoreTimestamp:'M/D HH:mm' }}</td>
    <td><app-status [status]="exportLog.status"></app-status></td>
  </tr>
  <tr *ngIf="exportLogs.length === 0">
    <td colspan="2" class="text-center">No export logs found</td>
  </tr>
</table>
