import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { IBankAccount } from '../../../../shared/model/bank-account.model';
import { BankAccountService } from '../../../../shared/services/bank-account.service';
import { IconService } from '../../../../shared/services/icon.service';
import { IExportSettingsTwinfield } from './export-settings-twinfield.model';
import { ExportSettingsTwinfieldService } from './export-settings-twinfield.service';

@Component({
  selector: 'app-export-settings-twinfield',
  templateUrl: './export-settings-twinfield.component.html',
  styleUrls: ['./export-settings-twinfield.component.scss']
})
export class ExportSettingsTwinfieldComponent implements OnInit {

  @Input()
  public bankAccount!: IBankAccount;

  public companies$!: Observable<string[]>;

  public form = new FormGroup({
    companyCode: new FormControl('', Validators.required),
  });

  public connected = false;
  private settings!: IExportSettingsTwinfield;

  constructor(
    private iconService: IconService,
    private bankAccountService: BankAccountService,
    private exportSettingsTwinfieldService: ExportSettingsTwinfieldService,
    private router: Router,
  ) {
    this.iconService.loadIcons([
      'company',
      'lock',
    ]);
  }

  public ngOnInit(): void {
    this.bankAccountService.update({ ...this.bankAccount });
    this.exportSettingsTwinfieldService.get(this.bankAccount.id).subscribe((settings) => {
      this.settings = settings;
      this.connected = (settings && !!settings.accessToken && !!settings.refreshToken);
      this.form.patchValue(settings);
    });
    this.companies$ = this.exportSettingsTwinfieldService.getCompanies(this.bankAccount.id);
  }

  public save(): void {
    if (this.form.valid) {
      const settings = this.parseSettings();
      const bankAccountId = this.bankAccount.id;
      const saveBankAccount = this.bankAccountService.update(this.bankAccount);
      const saveSettings = this.exportSettingsTwinfieldService.save(bankAccountId, settings);
      Promise.all([
        saveBankAccount,
        saveSettings,
      ]).then(() => {
        this.router.navigate(['/bank-accounts', bankAccountId]);
      });
    }
  }

  private parseSettings(): IExportSettingsTwinfield {
    return {
      accessToken: this.settings.accessToken,
      clusterUrl: this.settings.clusterUrl,
      companyCode: this.form.get('companyCode')?.value,
      refreshToken: this.settings.refreshToken,
    };
  }

  public connect(): void {
    window.location.href = `${environment.api}/twinfieldAuthorize?bankAccountId=${this.bankAccount.id}`;
  }

  public cancel(): void {
    this.bankAccount.exportType = '';
    this.bankAccountService.update({ ...this.bankAccount }).then(() => {
      this.router.navigate(['/bank-accounts', this.bankAccount.id]);
    });
  }

}
