import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/auth';

declare var grecaptcha: any;

@Injectable({
  providedIn: 'root',
})
export class RecaptchaService {
  private recaptchaVerifier?: firebase.auth.RecaptchaVerifier;
  private widgetId?: number;

  constructor() {}

  /**
   * Initializes the reCAPTCHA verifier and renders the widget.
   */
  public async initializeRecaptcha(containerId: string): Promise<void> {
    if (!this.recaptchaVerifier) {
      this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(containerId, {
        size: 'invisible',
      });

      try {
        this.widgetId = await this.recaptchaVerifier.render();
      } catch (error) {
        console.error('reCAPTCHA failed to render:', error);
      }
    }
    else {
      console.error('reCAPTCHA verifier is already initialized');
    }
  }

  /**
   * Resets the reCAPTCHA widget.
   */
  public resetRecaptcha(): void {
    if (this.widgetId !== undefined && grecaptcha) {
      grecaptcha.reset(this.widgetId);
    } else {
      console.error('reCAPTCHA widget is not initialized');
    }
  }

  /**
   * Returns the current reCAPTCHA verifier instance.
   */
  public getRecaptchaVerifier(): firebase.auth.RecaptchaVerifier | undefined {
    return this.recaptchaVerifier;
  }

  /**
   * Clears the reCAPTCHA verifier instance.
   */
  public clearRecaptcha(): void {
    if (this.recaptchaVerifier) {
      this.recaptchaVerifier.clear();
      this.recaptchaVerifier = undefined;
      this.widgetId = undefined;
    }
  }
}
