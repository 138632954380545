<table *ngIf="importLogs$ | async as importLogs" class="data no-border">
  <tr>
    <th>Date time</th>
    <th>Status</th>
  </tr>
  <tr
    *ngFor="let importLog of importLogs"
    [routerLink]="['/bank-accounts', bankAccountId, 'import-logs', importLog.id]"
    [ngClass]="{
      error: importLog.status === 'ERROR'
    }"
  >
    <td>{{ importLog.datetime | firestoreTimestamp:'M/D HH:mm' }}</td>
    <td><app-status [status]="importLog.status"></app-status></td>
  </tr>
  <tr *ngIf="importLogs.length === 0">
    <td colspan="2" class="text-center">No import logs found</td>
  </tr>
</table>
