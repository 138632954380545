<app-container>

  <ng-container *ngIf="bankAccount">

    <div *ngIf="!bankAccount.importType">
      <ng-container *ngIf="importTypes$ | async as importTypes; else loading">
        <div
          *ngFor="let importType of importTypes"
          class="import-type clickable-item"
          (click)="setImportType(importType.code)"
        >
          <img src="/assets/images/import/{{ importType.code }}.png" />
          <h3>{{ importType.name }}</h3>
          <p>{{ importType.description }}</p>
        </div>
      </ng-container>
      <ng-template #loading>
        <app-spinner type="content-block"></app-spinner>
      </ng-template>
    </div>

    <app-content-block *ngIf="bankAccount.importType" cssStyle="form">

      <div class="selected-import-type">
        <img src="/assets/images/import/{{ bankAccount.importType }}.png" />
        <div class="change-import-type clickable-item" (click)="setImportType('')">
          <mat-icon svgIcon="change"></mat-icon><br />
          <span>Select different import type</span>
        </div>
      </div>

      <app-import-settings-wib-sftp
        *ngIf="bankAccount.importType === 'wib-sftp'"
        [bankAccount]="bankAccount"
      ></app-import-settings-wib-sftp>

      <app-import-settings-cmb-sftp
        *ngIf="bankAccount.importType === 'cmb-sftp'"
        [bankAccount]="bankAccount"
      ></app-import-settings-cmb-sftp>

      <app-import-settings-mcb-sftp
        *ngIf="bankAccount.importType === 'mcb-sftp'"
        [bankAccount]="bankAccount"
      ></app-import-settings-mcb-sftp>

      <app-import-settings-cibc-sftp
        *ngIf="bankAccount.importType === 'cibc-sftp'"
        [bankAccount]="bankAccount"
      ></app-import-settings-cibc-sftp>

      <app-import-settings-plaid
        *ngIf="bankAccount.importType === 'plaid'"
        [bankAccount]="bankAccount"
      ></app-import-settings-plaid>

    </app-content-block>

  </ng-container>

</app-container>
