import { Component, Input } from '@angular/core';
import { IBankAccount } from '../../model/bank-account.model';

@Component({
  selector: 'app-bank-accounts',
  templateUrl: './bank-accounts.component.html',
  styleUrls: ['./bank-accounts.component.scss']
})
export class BankAccountsComponent {

  @Input()
  public bankAccounts!: IBankAccount[];

}
