<div *ngIf="user$ | async as user" class="user">
  <div class="initials" [matMenuTriggerFor]="menu">
    {{ user.firstname?.substring(0, 1) }}{{ user.lastname?.substring(0, 1) }}
  </div>
  <mat-menu #menu="matMenu">
    <button mat-menu-item [routerLink]="['/profile']">Profile</button>
    <button mat-menu-item [routerLink]="['/mfa-settings']">MFA Settings</button>
    <button mat-menu-item (click)="signOut()">Sign out</button>
  </mat-menu>
</div>
