import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreatePage } from './pages/create/create.page';
import { DetailsPage } from './pages/details/details.page';
import { EditPage } from './pages/edit/edit.page';
import { ListPage } from './pages/list/list.page';
import { AuthGuard } from '../../shared/guards/auth';
import { UsersPage } from './pages/users/users.page';
import { UsersInvitePage } from './pages/users-invite/users-invite.page';
import { ProfileCompletionGuard } from 'src/app/shared/guards/profile-complete';
import { MFAEnrollmentGuard } from 'src/app/shared/guards/mfa-enrollment';

const routes: Routes = [
  {
    path: 'companies',
    canActivate:[AuthGuard, MFAEnrollmentGuard, ProfileCompletionGuard],
    children: [
      {
        path: '',
        component: ListPage,
      },
      {
        path: 'create',
        component: CreatePage,
      },
      {
        path: ':companyId',
        component: DetailsPage,
      },
      {
        path: ':companyId/edit',
        component: EditPage,
      },
      {
        path: ':companyId/users',
        component: UsersPage,
      },
      {
        path: ':companyId/users/invite',
        component: UsersInvitePage,
      },
    ],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class CompanyRoutingModule { }
