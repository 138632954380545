import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { IBankAccount } from '../../../../shared/model/bank-account.model';
import { BankAccountService } from '../../../../shared/services/bank-account.service';
import { IconService } from '../../../../shared/services/icon.service';
import { IAccount, IExportSettingsQuickBooksOnline } from './export-settings-quick-books-online.model';
import { ExportSettingsQuickBooksOnlineService } from './export-settings-quick-books-online.service';

@Component({
  selector: 'app-export-settings-quick-books-online',
  templateUrl: './export-settings-quick-books-online.component.html',
  styleUrls: ['./export-settings-quick-books-online.component.scss']
})
export class ExportSettingsQuickBooksOnlineComponent implements OnInit {

  @Input()
  public bankAccount!: IBankAccount;

  public accounts$!: Observable<IAccount[]>;

  public form = new FormGroup({
    bankAccount: new FormControl('', Validators.required),
    suspenseAccount: new FormControl('', Validators.required),
  });

  public connected = false;
  private settings!: IExportSettingsQuickBooksOnline;

  constructor(
    private iconService: IconService,
    private bankAccountService: BankAccountService,
    private exportSettingsQuickBooksOnlineService: ExportSettingsQuickBooksOnlineService,
    private router: Router,
  ) {
    this.iconService.loadIcons([
      'company',
      'lock',
    ]);
  }

  public ngOnInit(): void {
    this.exportSettingsQuickBooksOnlineService.get(this.bankAccount.id).subscribe((settings) => {
      this.settings = settings;
      this.connected = (settings && !!settings.accessToken && !!settings.refreshToken);
      this.form.patchValue(settings);
    });
    this.accounts$ = this.exportSettingsQuickBooksOnlineService.getAccounts(this.bankAccount.id).pipe(
      shareReplay(1),
    );
  }

  public save(): void {
    if (this.form.valid) {
      const settings = this.parseSettings();
      const bankAccountId = this.bankAccount.id;
      const saveBankAccount = this.bankAccountService.update(this.bankAccount);
      const saveSettings = this.exportSettingsQuickBooksOnlineService.save(bankAccountId, settings);
      Promise.all([
        saveBankAccount,
        saveSettings,
      ]).then(() => {
        this.router.navigate(['/bank-accounts', bankAccountId]);
      });
    }
  }

  private parseSettings(): IExportSettingsQuickBooksOnline {
    return {
      accessToken: this.settings.accessToken,
      refreshToken: this.settings.refreshToken,
      companyId: this.settings.companyId,
      bankAccount: this.form.get('bankAccount')?.value,
      suspenseAccount: this.form.get('suspenseAccount')?.value,
    };
  }

  public connect(): void {
    // Make sure the exportType is saved
    this.bankAccountService.update({ ...this.bankAccount }).then(() => {
      window.location.href = `${environment.api}/quickBooksOnlineAuthorize?bankAccountId=${this.bankAccount.id}`;
    });
  }

  public cancel(): void {
    this.bankAccount.exportType = '';
    this.bankAccountService.update({ ...this.bankAccount }).then(() => {
      this.router.navigate(['/bank-accounts', this.bankAccount.id]);
    });
  }

}
