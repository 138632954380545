import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { from, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import firebase from 'firebase/app';

import { IUser } from '../model/user.model';

export interface LoginResponse {
  success: boolean;
  user?: firebase.User;
  emailVerified?: boolean;
  mfaRequired?: boolean;
  verificationId?: string;
  resolver?: any;
  error?: any;
  phoneInfoOptions?: any;
}

const ADMINS = [
  'r.brouwer@tactly.io',
  'k.daniel@tactly.io',
  's.daniel@tactbv.com',
];

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private readonly USER_KEY = 'tactly-user';

  constructor(
    private auth: AngularFireAuth,
  ) { }

  public authState: Observable<firebase.User | null> = this.auth.authState;
  public onAuthStateChanged = this.auth.onAuthStateChanged;

  private get user(): IUser | null {
    const userData = localStorage.getItem(this.USER_KEY);
    return userData ? JSON.parse(userData) : null;
  }

  private set user(user: IUser | null) {
    if (user) {
      localStorage.setItem(this.USER_KEY, JSON.stringify(user));
    } else {
      localStorage.removeItem(this.USER_KEY);
    }
  }

  public isValid(): boolean {
    return this.user !== null;
  }

  public async login(
    email: string,
    password: string,
    recaptchaVerifier: firebase.auth.RecaptchaVerifier
  ): Promise<LoginResponse> {
    try {
      const response = await this.auth.signInWithEmailAndPassword(email, password);
      const user = response.user;

      if (!user) {
        throw new Error('Logged in user is null');
      }

      if (!user.emailVerified) {
        return { success: false, emailVerified: false };
      }

      this.setUser(user);
      return { success: true, user };

    } catch (error: any) {
      if (error.code === 'auth/multi-factor-auth-required') {
        return this.handleMultiFactorAuth(error, recaptchaVerifier);
      }

      console.error('Login error:', error);
      return { success: false, mfaRequired: false, error };
    }
  }

  private async handleMultiFactorAuth(
    error: any,
    recaptchaVerifier: firebase.auth.RecaptchaVerifier
  ): Promise<LoginResponse> {
    const resolver = error.resolver;
    const selectedIndex = 0;

    if (resolver.hints[selectedIndex].factorId === firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID) {
      const phoneInfoOptions = {
        multiFactorHint: resolver.hints[selectedIndex],
        session: resolver.session,
      };
      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

      try {
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerifier
        );
        return {
          success: false,
          mfaRequired: true,
          verificationId,
          resolver,
          phoneInfoOptions
        };
      } catch (error) {
        return { success: false, mfaRequired: false, error };
      }
    }

    return { success: false, mfaRequired: false, error };
  }

  public async resendVerificationCode(
    phoneInfoOptions: any,
    recaptchaVerifier: firebase.auth.RecaptchaVerifier
  ): Promise<string> {
    const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
    return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
  }

  public async verifyCode(
    verificationId: string,
    verificationCode: string,
    resolver: any
  ): Promise<firebase.auth.UserCredential> {
    const phoneCredential = firebase.auth.PhoneAuthProvider.credential(
      verificationId,
      verificationCode
    );
    const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(
      phoneCredential
    );

    try {
      const userCredential = await resolver.resolveSignIn(multiFactorAssertion);
      if (userCredential.user) {
        this.setUser(userCredential.user);
      }
      return userCredential;
    } catch (error) {
      throw error;
    }
  }

  private setUser(user: firebase.User): void {
    const role = ADMINS.includes(user.email || '') ? 'admin' : 'user'; // Ugly workaround

    this.user = {
      firstname: '',
      lastname: '',
      displayName: user.displayName || '',
      role,
      email: user.email || '',
    };
  }

  public isAdmin(): boolean {
    return this.user?.role === 'admin';
  }

  public async logout(): Promise<void> {
    this.user = null;
    await this.auth.signOut();
    // Let the component handle navigation
  }

  public getToken(): Observable<string | null> {
    return from(this.auth.currentUser).pipe(
      switchMap((user) => user ? from(user.getIdToken()) : of(null))
    );
  }

  public async signInWithToken(token: string): Promise<void> {
    try {
      const response = await this.auth.signInWithCustomToken(token);
      if (response.user) {
        this.setUser(response.user);
      }
    } catch (error) {
      console.error('Error signing in with token:', error);
    }
  }

  public async sendPasswordResetEmail(email: string): Promise<void> {
    try {
      await this.auth.sendPasswordResetEmail(email);
    } catch (error: any) {
      if (error.code === 'auth/user-not-found') {
        throw new Error('User does not exist');
      } else {
        throw error;
      }
    }
  }

}
