<app-container [authenticationRequired]="false">
  <app-content-block cssStyle="form">
    <form [formGroup]="form" (ngSubmit)="sendPasswordResetEmail()">
      <h2>Password Reset</h2>
      <p>
        Enter your email address below and we'll send you a link to reset your password.
      </p>
      <mat-form-field class="full-width-input" appearance="outline">
        <mat-label>Email address</mat-label>
        <input matInput placeholder="Email address" formControlName="email">
        <mat-icon matSuffix svgIcon="account"></mat-icon>
        <mat-error>
          Please provide a valid email address
        </mat-error>
      </mat-form-field>
      <button mat-flat-button color="primary" type="submit" [disabled]="sendingPWResetMail">Send password reset email</button>
    </form>
  </app-content-block>
</app-container>
