<app-container>
  <app-content-block cssStyle="white">
    <ng-container *ngIf="bankAccount$ | async as bankAccount">
      <table *ngIf="statements$ | async as statements" class="data">
        <tr>
          <th>Date</th>
          <th>Number</th>
          <th>Transactions</th>
          <th>Exported</th>
        </tr>
        <tr
          *ngFor="let statement of statements"
        >
          <td>{{ statement.date | dateString:'M/D' }}</td>
          <td>{{ statement.number }}</td>
          <td class="text-center">{{ statement.numberOfTransactions }}</td>
          <td class="text-center icon">
            <mat-icon [svgIcon]="(statement.isExported) ? 'checked' : 'unchecked'"></mat-icon>
          </td>
        </tr>
        <tr *ngIf="statements.length === 0">
          <td colspan="4" class="text-center">No statements found</td>
        </tr>
      </table>
      <div class="text-center">
        <button
        mat-flat-button
        color="primary"
        [routerLink]="['/bank-accounts', bankAccount.id]"
        >
          Return to {{ bankAccount.name }}
        </button>
      </div>
    </ng-container>
  </app-content-block>
</app-container>
