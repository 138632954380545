import { Component, Input, OnInit } from '@angular/core';
import { ILogStatusEnum } from '../../model/log.model';
import { IconService } from '../../services/icon.service';

@Component({
  selector: 'app-status-icon',
  templateUrl: './status-icon.component.html',
  styleUrls: ['./status-icon.component.scss']
})
export class StatusIconComponent implements OnInit {

  @Input()
  public status: ILogStatusEnum = ILogStatusEnum.NONE;

  public icons = {
    [ILogStatusEnum.SUCCESS]: 'checked',
    [ILogStatusEnum.RUNNING]: 'info',
    [ILogStatusEnum.ERROR]: 'error',
    [ILogStatusEnum.NONE]: 'unchecked',
  };

  constructor(
    private iconService: IconService,
  ) {
    this.iconService.loadIcons([
      'checked',
      'unchecked',
      'info',
      'error',
    ]);
  }

  public ngOnInit(): void {
    if (!this.status) {
      this.status = ILogStatusEnum.NONE;
    }
  }

}
