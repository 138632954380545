import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { IBankAccount } from '../../../../shared/model/bank-account.model';
import { IStatement } from '../../../../shared/model/statement.model';
import { BankAccountService } from '../../../../shared/services/bank-account.service';
import { StatementService } from '../../../../shared/services/statement.service';

@Component({
  selector: 'app-statements-page',
  templateUrl: './statements.page.html',
  styleUrls: ['./statements.page.scss']
})
export class StatementsPage implements OnInit {

  public statements$!: Observable<IStatement[]>;
  public bankAccount$!: Observable<IBankAccount>;

  constructor(
    private bankAccountService: BankAccountService,
    private statementService: StatementService,
    private route: ActivatedRoute,
  ) { }

  public ngOnInit(): void {
    const bankAccountId = Number(this.route.snapshot.paramMap.get('bankAccountId'));
    this.bankAccount$ = this.bankAccountService.get(bankAccountId);
    this.statements$ = this.statementService.getByBankAccountId(bankAccountId);
  }

}
