<app-container [authenticationRequired]="true">
  <app-content-block cssStyle="form">
    <h2>Multi-Factor Authentication Settings</h2>

    <!-- Display Enrolled Phone Number -->
    <div *ngIf="enrolledPhoneNumber && !showReauthForm">
      <p>Your enrolled phone number: <strong>{{ enrolledPhoneNumber }}</strong></p>
      <p class="warning-text">
        Resetting MFA will remove your enrolled phone number and require you to set up MFA again before accessing your account.
        Please make sure you have access to your new phone to complete this process.
      </p>
      <button mat-flat-button color="warn" (click)="promptResetMfa()">
        Reset Multi-Factor Authentication
      </button>
    </div>

    <!-- No Enrolled Phone Number -->
    <div *ngIf="!enrolledPhoneNumber && !showReauthForm">
      <p>You do not have Multi-Factor Authentication enabled.</p>
      <a routerLink="/enroll-mfa">
        <button mat-flat-button color="primary">Enable Multi-Factor Authentication</button>
      </a>

    </div>
  </app-content-block>
</app-container>
