<app-container [authenticationRequired]="true">
  <app-content-block cssStyle="form">

    <!-- Enrollment Form -->
    <div *ngIf="!verificationInProgress && !recoveryCode">
      <form [formGroup]="form" (ngSubmit)="startEnrollment()">
        <h2>Enroll in Multi-Factor Authentication</h2>
        <p>
          Your security is important to us, which is why we require Multi-Factor Authentication (MFA). MFA adds an extra layer of security by making sure that only you can access your account—even if someone else knows your password.
        </p>
        <mat-error *ngIf="errorMessage" class="error-message">{{ errorMessage }}</mat-error>
        <div>
          <mat-form-field class="full-width-input" appearance="outline">
            <mat-label>Phone Number</mat-label>
            <input
                matInput
                placeholder="+316********"
                formControlName="phoneNumber"
                required
            />
            <mat-error>{{ errorMessage || 'Please enter a valid phone number with country code (e.g., +316 for Dutch mobiles).' }}</mat-error>
            <mat-hint>International number format (e.g., +31612345678)</mat-hint>
          </mat-form-field>
        </div>
        <button mat-flat-button color="primary" class="send-button">Send Verification Code</button>
        <p>
          <a href="https://voipstudio.com/blog/what-is-the-e-164-format-and-how-to-use-it-correctly/" target="_blank" rel="noopener noreferrer">
            Need help with the format?
          </a>
        </p>
      </form>
    </div>

    <!-- Verification Form -->
    <div *ngIf="verificationInProgress && !recoveryCode">
      <form [formGroup]="verificationForm" (ngSubmit)="verifyCode()">
        <h2>Verify Multi-Factor Authentication</h2>
        <p>
          A verification code has been sent to your phone. Please enter it below:
        </p>
        <mat-error *ngIf="errorMessage" class="error-message">{{ errorMessage }}</mat-error>
        <div>
          <mat-form-field class="full-width-input" appearance="outline">
            <mat-label>Verification Code</mat-label>
            <input matInput formControlName="verificationCode" required />
            <mat-error>Please enter the verification code</mat-error>
          </mat-form-field>
        </div>
        <button mat-flat-button color="primary" class="send-button">Verify Code</button>
        <div class="account-link-div">
          <ul>
            <li><a (click)="resendCode()" class="clickable-link">Resend Verification Code</a></li>
          </ul>
        </div>
      </form>
    </div>

    <!-- Recovery Code Display -->
    <div *ngIf="recoveryCode">
      <form action=""> <!-- Wrapped in form to inherit styling. -->
        <h2>Recovery Code</h2>
        <p>
          Your Multi-Factor Authentication has been successfully enabled. Please save the following recovery code in a safe place. You will need this code to access your account if you lose access to your phone.
        </p>
        <p class="recovery-code">{{ recoveryCode }}</p>
        <button mat-flat-button color="primary" (click)="navigateHome()">Go to Home</button>
      </form>
    </div>
  </app-content-block>
</app-container>

