import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginPage } from './pages/login/login.page';
import { LogoutPage } from './pages/logout/logout.page';
import { ProfilePage } from './pages/profile/profile.page';
import { AuthGuard } from 'src/app/shared/guards/auth';
import { SignupLinkPage } from './pages/signup-link/signup-link.page';
import { SendEmailVerificationPage } from './pages/send-email-verification/send-email-verification.page';
import { PasswordResetPage } from './pages/password-reset/password-reset.page';
import { EnrollMfaPage } from './pages/enroll-mfa/enroll-mfa.page';
import { MFAEnrollmentGuard } from 'src/app/shared/guards/mfa-enrollment';
import { MfaSettingsPage } from './pages/mfa-settings/mfa-settings.page';
import { ReauthenticationPage } from './pages/reauthentication/reauthentication.page';

const routes: Routes = [
  {
    path: 'login',
    component: LoginPage,
  },
  {
    path: 'logout',
    component: LogoutPage,
  },
  {
    path: 'profile',
    canActivate: [MFAEnrollmentGuard, AuthGuard],
    component: ProfilePage,
  },
  {
    path: 'signup',
    component: SignupLinkPage,
  },
  {
    path: 'send-email-verification',
    component: SendEmailVerificationPage,
  },
  {
    path: 'password-reset',
    component: PasswordResetPage,
  },
  {
    path: 'enroll-mfa',
    canActivate: [AuthGuard],
    component: EnrollMfaPage,
  },
  {
    path: 'mfa-settings',
    canActivate: [MFAEnrollmentGuard, AuthGuard],
    component: MfaSettingsPage,
  },
  {
    path: 'reauthenticate',
    canActivate: [AuthGuard],
    component: ReauthenticationPage,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
