<h3>Bank accounts</h3>

<table class="list dark">

  <tr *ngIf="bankAccounts.length > 0">
    <th>Name</th>
    <th>Account</th>
    <th>Import</th>
    <th>Export</th>
    <th>Active</th>
  </tr>

  <tr
    *ngFor="let bankAccount of bankAccounts"
    [routerLink]="['/bank-accounts', bankAccount.id]"
    class="clickable"
  >
    <td [ngClass]="{ green: bankAccount.active, red: !bankAccount.active}">{{ bankAccount.name }}</td>
    <td>{{ bankAccount.account }}</td>
    <td>
      <app-import-icon [importType]="bankAccount.importType"></app-import-icon>
      <app-status-icon [status]="bankAccount.lastImportStatus"></app-status-icon>
    </td>
    <td>
      <app-export-icon [exportType]="bankAccount.exportType"></app-export-icon>
      <app-status-icon [status]="bankAccount.lastExportStatus"></app-status-icon>
    </td>
    <td>{{ (bankAccount.active) ? 'Yes' : 'No' }}</td>
  </tr>

  <tr *ngIf="bankAccounts.length === 0">
    <td colspan="3" class="message">No bank accounts found. Click the "Add a new bank account" button to create one.</td>
  </tr>

</table>