import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-block',
  templateUrl: './content-block.component.html',
  styleUrls: ['./content-block.component.scss']
})
export class ContentBlockComponent {

  @Input()
  public cssStyle: 'primary' | 'secondary' | 'white' | 'light' | 'form' = 'primary';

  @Input()
  public hasMaxWidth = true;

  @Input()
  public hasPadding = true;

}
