import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { RecaptchaService } from './shared/services/recaptcha.service';
import firebase from 'firebase/app';

@Component({
  selector: 'app-root',
  template: '<div id="recaptcha-container"></div><router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {

  public constructor(
    private titleService: Title,
    private recaptchaService: RecaptchaService,
  ) { }

  public ngOnInit(): void {
    this.titleService.setTitle(environment.title);
    this.initializeFirebase();
  }

  ngAfterViewInit(): void {
    this.recaptchaService.initializeRecaptcha('recaptcha-container');
  }

  private initializeFirebase(): void {
    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebase);
    }
  }
}
