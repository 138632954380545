import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IExportType } from '../model/export-type.model';
import { IImportType } from '../model/import-type.model';

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {

  constructor(
    private http: HttpClient,
  ) { }

  public getImportTypes(): Observable<IImportType[]> {
    const url = `${environment.api}/v2/import-types`;
    return this.http.get<IImportType[]>(url);
  }

  public getExportTypes(): Observable<IExportType[]> {
    const url = `${environment.api}/v2/export-types`;
    return this.http.get<IExportType[]>(url);
  }
}
