import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { UserService } from "src/app/shared/services/user.service";

@Component({
  selector: 'app-signup-link-page',
  templateUrl: './signup-link.page.html',
  styleUrls: ['./signup-link.page.scss']
})
export class SignupLinkPage {
  isSubmitting: boolean = false;
  submitSuccess: boolean = false;
  submitError: boolean = false;
  errorMessage: string = '';

  public form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
  });

  constructor(
    private userService: UserService
  ) {}

  submitForm(): void {
    if (this.form.valid) {
      this.isSubmitting = true;
      this.submitSuccess = false;
      this.submitError = false;

      const email = this.form.get('email')!.value;
      const password = this.form.get('password')!.value;

      this.userService.signUpAndSendVerificationLink(email, password)
        .then(() => {
          this.isSubmitting = false;
          this.submitSuccess = true;
        })
        .catch((error) => {
          this.isSubmitting = false;
          this.submitError = true;
          if(error.code === 'auth/email-already-in-use') {
            this.errorMessage = 'The email address is already in use. Please check for typos or try logging in.';
            return;
          }
          this.errorMessage = error.message;
        });
    }
  }
}
