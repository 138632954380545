import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'dateString' })
export class DateStringPipe implements PipeTransform {
  transform(value: string, format = 'YYYY-MM-DD'): string {
    const date = moment(value);
    return date.format(format);
  }
}
