<app-container>

  <app-content-block cssStyle="light">

    <ng-container *ngIf="bankAccount$ | async as bankAccount">

      <app-import-tools-wib-sftp [bankAccount]="bankAccount"></app-import-tools-wib-sftp>
    
      <button
        mat-button
        color="basic"
        [routerLink]="['/bank-accounts', bankAccount.id]"
      >
        Return to bank account
      </button>
      
    </ng-container>

  </app-content-block>

</app-container>
