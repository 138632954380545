<app-container [authenticationRequired]="false">
  <app-content-block cssStyle="form">
    <ng-container *ngIf="!submitSuccess">
      <form [formGroup]="form" (ngSubmit)="submitForm()">
        <h2>Create Account</h2>
        <p>Please enter your email and password to get started. We'll send you a verification email to activate your
          account.</p>

        <div>
          <mat-form-field class="full-width-input" appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Email" formControlName="email">
            <mat-error>
              Please provide a valid email address
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="full-width-input" appearance="outline">
            <mat-label>Password</mat-label>
            <input matInput type="password" placeholder="Password" formControlName="password">
            <mat-icon matSuffix svgIcon="lock"></mat-icon>
            <mat-error>
              Please provide a valid password
            </mat-error>
          </mat-form-field>
        </div>

        <div *ngIf="isSubmitting">
          <p>Submitting...</p>
        </div>
        <div *ngIf="submitError" class="error-message">
          <p>{{errorMessage}}</p>
        </div>

        <button
          *ngIf="!isSubmitting"
          [disabled]="!form.valid"
          mat-flat-button
          color="primary">
            Submit
        </button>
      </form>
    </ng-container>

    <div *ngIf="submitSuccess">
      <p>
        Thank you for signing up! We've sent a verification link to your email address. Please check your inbox to
        activate your account. You can close this window.
      </p>
    </div>

  </app-content-block>
</app-container>
