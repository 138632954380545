import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { IAccount, IExportSettingsQuickBooksOnline } from './export-settings-quick-books-online.model';

@Injectable({
  providedIn: 'root'
})
export class ExportSettingsQuickBooksOnlineService {

  constructor(
    private http: HttpClient,
  ) { }

  public get(bankAccountId: number): Observable<IExportSettingsQuickBooksOnline> {
    const url = `${environment.api}/v2/bank-accounts/${bankAccountId}/export-settings/quick-books-online`;
    return this.http.get<IExportSettingsQuickBooksOnline>(url);
  }

  public getAccounts(bankAccountId: number): Observable<IAccount[]> {
    const url = `${environment.api}/quickBooksOnlineAccounts?bankAccountId=${bankAccountId}`;
    return this.http.get<IAccount[]>(url);
  }

  public save(bankAccountId: number, settings: IExportSettingsQuickBooksOnline): Promise<void> {
    const url = `${environment.api}/v2/bank-accounts/${bankAccountId}/export-settings/quick-books-online`;
    return this.http.post<void>(url, settings).toPromise();
  }

}
