export const environment = {
  production: true,
  emulator: false,
  api: 'https://us-central1-tactly-test.cloudfunctions.net/',
  firebase: {
    apiKey: 'AIzaSyDAYEP48QeAynXPY73DmbVYcO870TaOSXA',
    authDomain: 'test-app.tactly.io',
    projectId: 'tactly-test',
    storageBucket: 'tactly-test.appspot.com',
    messagingSenderId: '848073763622',
    appId: '1:848073763622:web:790659094264a75c992fe7'
  },
  title: 'Tactly - Test',
};
