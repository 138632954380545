import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { countries } from 'src/app/shared/utils/countries';
import { IUser } from 'src/app/shared/model/user.model';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {
  @Output() formSubmitted = new EventEmitter<IUser>();
  @Input() isSubmitting: boolean = false;
  @Input() submitSuccess: boolean = false;
  @Input() submitError: boolean = false;

  @Input() formTitle = "Form Title";
  @Input() formButtonText = "Submit";

  @Input() user: IUser | null = null;

  submitSuccessMessage = "Saved!";
  submitErrorMessage = "Error!";

  form!: FormGroup;
  filteredCountries$: Observable<string[]> | undefined;
  countryControl = new FormControl();
  countryFilterControl = new FormControl();
  private readonly _subscriptions = new Subscription();

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      email: [{ value: '', disabled: true }, [Validators.required, Validators.email]],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      country: ['', Validators.required]
    });

    this.filteredCountries$ = this.countryFilterControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterCountries(value))
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user && this.user) {
      this.form.patchValue(this.user);

      // Check if the selected country is valid
      const selectedCountry = this.form.get('country')?.value;
      if (!countries.includes(selectedCountry) && selectedCountry) {
        this.form.patchValue({ country: '' });
        this.snackBar.open(
          `The country you previously selected (${selectedCountry}) is no longer available. Please choose a new country.`,
          'Dismiss',
          {
            duration: 15000,
            panelClass: ['error-snackbar']
          }
        );
      }
    }
  }

  private _filterCountries(value: string): string[] {
    const filterValue = value.toLowerCase();
    return countries.filter(country => country.toLowerCase().includes(filterValue));
  }

  submitForm(): void {
    if (this.form.valid) {
      const user: IUser = this.form.value;
      user.displayName = `${user.firstname} ${user.lastname}`;
      this.formSubmitted.emit(user);
      this.form.markAsPristine();
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
}
