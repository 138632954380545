import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export interface RecoveryResponse {
  success: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class RecoveryService {
  private apiUrl: string = `${environment.api}/v2/recovery`;

  constructor(
    private http: HttpClient
  ) {}

  public verifyCodeAndResetMfa(recoveryCode: string): Observable<RecoveryResponse> {
    return this.http.post<RecoveryResponse>(this.apiUrl + '/reset-mfa', { recoveryCode });
  }

  public getBackupCode(firebaseId: string): Observable<string> {
    return this.http.post<{ backupCode: string }>(
      this.apiUrl + `/${firebaseId}/backup-code`,
      {}
    ).pipe(
      map(response => response.backupCode)
    );
  }

  public deleteBackupCode(firebaseId: string): Observable<{ message: string }> {
    return this.http.delete<{ message: string }>(
      this.apiUrl + `/${firebaseId}/backup-code`
    );
  }
}
