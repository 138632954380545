import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IBankAccount } from '../../../../shared/model/bank-account.model';
import { IImportSettingsWibSftp } from '../../../../shared/model/import-settings/import-settings-wib-sftp.model';
import { ImportSettingsWibSftpService } from '../../../../shared/services/import-settings/import-settings-wib-sftp.service';

@Component({
  selector: 'app-import-tools-wib-sftp',
  templateUrl: './import-tools-wib-sftp.component.html',
  styleUrls: ['./import-tools-wib-sftp.component.scss']
})
export class ImportToolsWibSftpComponent implements OnInit {

  @Input()
  public bankAccount!: IBankAccount;

  public settings$!: Observable<IImportSettingsWibSftp>;

  constructor(
    private importSettingsWibSftpService: ImportSettingsWibSftpService,
  ) { }

  public ngOnInit(): void {
    this.settings$ = this.importSettingsWibSftpService.get(this.bankAccount.id);
  }

}
