import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IconService } from '../../../../shared/services/icon.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent {

  @Input()
  public form!: FormGroup;

  constructor(
    private iconService: IconService,
  ) {
    this.iconService.loadIcon('company');
  }

}
