import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  private apiUrl = 'https://restcountries.com/v3.1/all';

  constructor(private http: HttpClient) {}

  getCountries(): Observable<any> {
    return this.http.get<any[]>(this.apiUrl).pipe(
      map(countries => countries.filter(country => country.cca3 !== 'BES')) // Filter out Caribbean Netherlands using cca3 code
    );
  }
}
