import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  async canActivate(): Promise<boolean> {
    await this.waitForAuthInit();
    const user = await this.authService.authState.pipe(take(1)).toPromise();

    if (user && user.emailVerified) {
      return true;
    } else if (user && !user.emailVerified) {
      this.snackBar.open('Please verify your email address', 'Dismiss', {
        duration: 15000,
        panelClass: ['success-snackbar'],
      });
      this.router.navigate(['/send-email-verification']);
      return false;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }

  private async waitForAuthInit() {
    let unsubscribe: Promise<firebase.default.Unsubscribe>;
    await new Promise<void>((resolve) => {
      unsubscribe = this.authService.onAuthStateChanged((_) => resolve());
    });
    (await unsubscribe!)();
  }
}
