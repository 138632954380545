import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of, Subscription } from 'rxjs';
import { map, startWith, switchMap, take } from 'rxjs/operators';
import { extraCountries } from 'src/app/shared/utils/extra-countries';
import { IUser } from 'src/app/shared/model/user.model';
import { CountryService } from 'src/app/shared/services/country.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {
  @Output() formSubmitted = new EventEmitter<IUser>();
  @Input() isSubmitting: boolean = false;
  @Input() submitSuccess: boolean = false;
  @Input() submitError: boolean = false;

  @Input() formTitle = "Form Title";
  @Input() formButtonText = "Submit";

  @Input() user: IUser | null = null;

  submitSuccessMessage = "Saved!";
  submitErrorMessage = "Error!";

  form!: FormGroup;
  countries$: Observable<string[]> | undefined;
  filteredCountries$: Observable<string[]> | undefined;
  countryControl = new FormControl();
  countryFilterControl = new FormControl();
  private readonly _subscriptions = new Subscription();



  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private countryService: CountryService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      email: [{ value: '', disabled: true }, [Validators.required, Validators.email]],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      country: ['', Validators.required]
    });

    const countriesObservable = this.countryService.getCountries().pipe(
      take(1),
      map((countries) => [...new Set([...countries.map((country: any) => country.name.common), ...extraCountries])])
    );

    this.countries$ = countriesObservable;

    this.filteredCountries$ = this.countryFilterControl.valueChanges.pipe(
      startWith(''),
      switchMap(value => this._filterCountries(value))
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user && this.user) {
      this.form.patchValue(this.user);

      // Check if the selected country is valid
      const countrySubscription = this.countries$?.pipe(take(1)).subscribe(apiCountries => {
        const selectedCountry = this.form.get('country')?.value;
        if (!apiCountries.includes(selectedCountry) && selectedCountry) {
          this.form.patchValue({ country: '' });
          this.snackBar.open(
            `The country you previously selected (${selectedCountry}) is no longer available. Please choose a new country.`,
            'Dismiss',
            {
              duration: 15000,
              panelClass: ['error-snackbar']
            }
          );
        }
      });
      this._subscriptions.add(countrySubscription);
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  private _filterCountries(value: string): Observable<string[]> {
    const filterValue = value.toLowerCase();

    if (!this.countries$) {
      return of([]);
    }

    return this.countries$.pipe(
      map(countries => countries.filter(country => country.toLowerCase().includes(filterValue)))
    );
  }

  submitForm(): void {
    if (this.form.valid) {
      const user: IUser = this.form.value;
      user.displayName = `${user.firstname} ${user.lastname}`;
      this.formSubmitted.emit(user);
      this.form.markAsPristine();
    }
  }
}
