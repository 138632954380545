import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { IBankAccount } from '../../../../shared/model/bank-account.model';
import { BankAccountService } from '../../../../shared/services/bank-account.service';

@Component({
  selector: 'app-import-tools',
  templateUrl: './import-tools.page.html',
  styleUrls: ['./import-tools.page.scss']
})
export class ImportToolsPage implements OnInit {

  public bankAccount$!: Observable<IBankAccount>;

  constructor(
    private bankAccountServic: BankAccountService,
    private route: ActivatedRoute,
  ) { }

  public ngOnInit(): void {
    const bankAccountId = Number(this.route.snapshot.paramMap.get('bankAccountId'));
    this.bankAccount$ = this.bankAccountServic.get(bankAccountId);
  }

}
