<div class="folder" *ngIf="folders$ | async as folders; else loadingFolders">
  <mat-form-field appearance="outline">
    <mat-label>Folder</mat-label>
    <select matNativeControl [(ngModel)]="selectedFolder" (change)="loadFiles()">
      <option value=""></option>
      <option *ngFor="let folder of folders" [value]="folder">{{ folder }}</option>
    </select>
  </mat-form-field>
</div>

<ng-template #loadingFolders>
  <app-spinner *ngIf="!directoriesError">Loading folders</app-spinner>
  <app-alert type="error" *ngIf="directoriesError">{{ directoriesError }}</app-alert>
</ng-template>

<div *ngIf="files$ | async as files; else loadingFiles">
  <table class="list">
    <tr>
      <th>Filename</th>
      <th>Filesize</th>
      <th>Modify time</th>
      <th>Imported</th>
      <th></th>
    </tr>
    <tr *ngIf="files.length === 0">
      <td colspan="4">No files found</td>
    </tr>
    <tr *ngFor="let file of files">
      <td>{{ file.name }}</td>
      <td>{{ file.size | filesize }}</td>
      <td>{{ file.modifyTime | unixTimestamp:'YYYY-MM-DD HH:mm:ss' }}</td>
      <td>{{ file.isImported ? 'Yes' : 'No' }}</td>
      <td><button mat-flat-button color="primary" (click)="importFile(file)">Import</button></td>
    </tr>
  </table>
</div>

<ng-container *ngIf="importResponse$ | async as importResponse">
  <app-import-response [importResponse]="importResponse"></app-import-response>
</ng-container>

<ng-template #loadingFiles>
  <app-spinner *ngIf="!directoriesError">Loading files for "{{ selectedFolder }}" directory</app-spinner>
  <app-alert type="error" *ngIf="directoriesError">{{ directoriesError }}</app-alert>
</ng-template>