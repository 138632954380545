import { Component } from '@angular/core';
import { AuthService } from '../../../../shared/services/auth.service';
import { CompanyService } from '../../../../shared/services/company.service';
import { catchError, ignoreElements } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-list',
  templateUrl: './list.page.html',
  styleUrls: ['./list.page.scss']
})
export class ListPage {

  public companies$ = this.companyService.getAll();

  public error$ = this.companies$.pipe(
    ignoreElements(),
    catchError((error) => of(error)),
  );

  constructor(
    private companyService: CompanyService,
    private authService: AuthService,
  ) { }

  public isAdmin(): boolean {
    return this.authService.isAdmin();
  }

}
