import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import firebase from 'firebase/app';
import 'firebase/auth';

import { IConfirmData, ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { getErrorMessage, getErrorMessage as getFirebaseErrorMessage } from 'src/app/shared/utils/error-message';
import { RecoveryService } from 'src/app/shared/services/recovery-service';
import { RecaptchaService } from 'src/app/shared/services/recaptcha.service';


@Component({
  selector: 'app-reauthentication',
  templateUrl: './reauthentication.page.html',
  styleUrls: ['./reauthentication.page.scss']
})
export class ReauthenticationPage implements OnInit, AfterViewInit, OnDestroy {
  public reauthForm: FormGroup;
  public verificationForm: FormGroup;
  public verificationId: string | null = null;
  public resolver: firebase.auth.MultiFactorResolver | null = null;
  public errorMessage = '';
  public verificationErrorMessage = '';
  public action = '';

  private recaptchaVerifier?: firebase.auth.RecaptchaVerifier;
  private phoneInfoOptions?: firebase.auth.PhoneInfoOptions;

  private dialogSubscription: Subscription | undefined;

  constructor(
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private recoveryService: RecoveryService,
    private recaptchaService: RecaptchaService,
  ) {
    this.reauthForm = new FormGroup({
      password: new FormControl('', [Validators.required]),
    });
    this.verificationForm = new FormGroup({
      verificationCode: new FormControl('', [Validators.required]),
    });
    this.action = this.route.snapshot.queryParamMap.get('action') || '';
  }

  ngOnInit(): void {
    //
  }

  ngAfterViewInit(): void {
    this.recaptchaService.resetRecaptcha();
  }

  ngOnDestroy(): void {
    this.dialogSubscription?.unsubscribe();
  }

  public async reauthenticate(): Promise<void> {
    if (!this.reauthForm.valid) {
      return;
    }

    const password = this.reauthForm.get('password')!.value;
    const user = firebase.auth().currentUser;

    if (user && user.email) {
      const credential = firebase.auth.EmailAuthProvider.credential(user.email, password);

      try {
        await user.reauthenticateWithCredential(credential);
        this.snackBar.open('Reauthentication successful.', 'Close', {
          duration: 5000,
          panelClass: ['success-snackbar'],
        });
        this.postReauthenticationAction();
      } catch (error: any) {
        if (error.code === 'auth/multi-factor-auth-required') {
          this.resolver = error.resolver;
          await this.startMfaReauthentication();
        } else if (error.code === 'auth/wrong-password') {
          this.errorMessage = 'Incorrect password.';
        } else {
          console.error('Error during reauthentication:', error);
          this.errorMessage = getFirebaseErrorMessage(error);
        }
      }
    } else {
      this.errorMessage = 'User is not logged in or email is not available.';
    }
  }

  private async startMfaReauthentication(): Promise<void> {
    this.recaptchaVerifier = this.recaptchaService.getRecaptchaVerifier();
    if (!this.resolver) {
      this.errorMessage = 'MFA resolver is not available.';
      return;
    }
    if (!this.recaptchaVerifier) {
      this.errorMessage = 'reCAPTCHA verifier is not initialized.';
      return;
    }
    if (this.resolver.hints.length === 0) {
      this.errorMessage = 'No MFA hints are available.';
      return;
    }

    this.phoneInfoOptions = {
      multiFactorHint: this.resolver.hints[0],
      session: this.resolver.session,
    };
    const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

    try {
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        this.phoneInfoOptions,
        this.recaptchaVerifier
      );
      this.verificationId = verificationId;
    } catch (error: any) {
      console.error('Error sending verification code:', error);
      this.errorMessage = getFirebaseErrorMessage(error);
    }
  }

  public async resendCode(): Promise<void> {
    try {

      if (!this.phoneInfoOptions) {
        throw new Error('Phone number is not available.');
      }
      if (!this.recaptchaVerifier) {
        throw new Error('reCAPTCHA verifier is not initialized.');
      }

      this.recaptchaService.resetRecaptcha();

      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        this.phoneInfoOptions,
        this.recaptchaVerifier
      );
      this.verificationId = verificationId;
      this.snackBar.open('Verification code resent.', 'Close', {
        duration: 5000,
        panelClass: ['success-snackbar'],
      });
    } catch (error: any) {
      console.error('Error resending verification code:', error);
      this.errorMessage = getErrorMessage(error);
    }
  }

  public async verifyCode(): Promise<void> {
    if (!this.verificationForm.valid) {
      return;
    }
    if (!this.verificationId || !this.resolver) {
      this.verificationErrorMessage = 'Verification information is missing.';
      return;
    }

    const verificationCode = this.verificationForm.get('verificationCode')!.value;
    const cred = firebase.auth.PhoneAuthProvider.credential(this.verificationId, verificationCode);
    const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);

    try {
      await this.resolver.resolveSignIn(multiFactorAssertion);
      this.postReauthenticationAction();
    } catch (error: any) {
      if (error.code === 'auth/invalid-verification-code') {
        this.verificationErrorMessage = 'Incorrect verification code.';
      } else {
        console.error('Error verifying code:', error);
        this.verificationErrorMessage = getFirebaseErrorMessage(error);
      }
    }
  }

  private postReauthenticationAction(): void {
    this.snackBar.open('Reauthentication successful.', 'Close', {
      duration: 5000,
      panelClass: ['success-snackbar'],
    });
    const action = this.action;
    if (action === 'reset-mfa') {
      this.confirmFinalReset();
    } else if (action === 'enroll-mfa') {
      this.router.navigate(['/enroll-mfa']);
    } else {
      this.router.navigate(['/']);
    }
  }

  public confirmFinalReset(): void {
    const confirmData: IConfirmData = {
      title: 'Confirm Reset MFA',
      message: `
        You are about to reset Multi-Factor Authentication (MFA). This will remove your currently enrolled phone number, and you will need to set up MFA again before accessing your account.
        Are you sure you want to proceed?
      `,
      confirmButtonText: 'Reset MFA',
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: confirmData,
    });

    this.dialogSubscription = dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.resetMfa();
      } else {
        this.snackBar.open('MFA reset cancelled.', 'Close', {
          duration: 5000,
          panelClass: ['notification-snackbar'],
        });
        this.router.navigate(['/mfa-settings']);
      }
    });
  }

  private async resetMfa(): Promise<void> {
    const user = firebase.auth().currentUser;
    if (!user) {
      this.snackBar.open('No user is currently signed in.', 'Close', {
        duration: 5000,
        panelClass: ['error-snackbar'],
      });
      return;
    }

    try {
      await user.reload();
      const enrolledFactors = user.multiFactor.enrolledFactors;

      // Unenroll all enrolled MFA factors
      for (const factor of enrolledFactors) {
        await user.multiFactor.unenroll(factor.uid);
      }

      // Call the backend to delete the backup code
      await this.recoveryService.deleteBackupCode(user.uid).toPromise();

      // Show success message and navigate to the enrollment page
      this.snackBar.open('Multi-Factor Authentication has been reset.', 'Close', {
        duration: 15000,
        panelClass: ['success-snackbar'],
      });
      this.router.navigate(['/enroll-mfa']);
    } catch (error: any) {
      console.error('Error resetting MFA:', error);
      const errorMessage = getFirebaseErrorMessage(error);
      this.snackBar.open(errorMessage, 'Close', {
        duration: 15000,
        panelClass: ['error-snackbar'],
      });
    }
  }
}
