import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { IExportSettingsTwinfield } from './export-settings-twinfield.model';

@Injectable({
  providedIn: 'root'
})
export class ExportSettingsTwinfieldService {

  constructor(
    private http: HttpClient,
  ) { }

  public get(bankAccountId: number): Observable<IExportSettingsTwinfield> {
    const url = `${environment.api}/v2/bank-accounts/${bankAccountId}/export-settings/twinfield`;
    return this.http.get<IExportSettingsTwinfield>(url);
  }

  public getCompanies(bankAccountId: number): Observable<string[]> {
    const url = `${environment.api}/twinfieldCompanies?bankAccountId=${bankAccountId}`;
    return this.http.get<string[]>(url);
  }

  public save(bankAccountId: number, settings: IExportSettingsTwinfield): Promise<void> {
    const url = `${environment.api}/v2/bank-accounts/${bankAccountId}/export-settings/twinfield`;
    return this.http.post<void>(url, settings).toPromise();
  }

}
