import { Component, OnInit } from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/auth';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mfa-settings',
  templateUrl: './mfa-settings.page.html',
  styleUrls: ['./mfa-settings.page.scss'],
})
export class MfaSettingsPage implements OnInit {
  public showReauthForm: boolean = false;
  public errorMessage: string = '';
  public enrolledPhoneNumber: string | null = null;
  public user: firebase.User | null = null;

  constructor(
    private router: Router,
  ) {

  }

  ngOnInit(): void {
    this.initializeFirebase();
    this.loadEnrolledFactors();
  }

  private initializeFirebase(): void {
    // Initialize Firebase if not already initialized
    if (firebase.apps.length === 0) {
      firebase.initializeApp(environment.firebase);
    }
  }

  private async loadEnrolledFactors(): Promise<void> {
    this.user = firebase.auth().currentUser;
    if (this.user) {
      await this.user.reload();
      const enrolledFactors = this.user.multiFactor.enrolledFactors;

      // Find the enrolled phone number
      for (const factor of enrolledFactors) {
        if (factor.factorId === 'phone') {
          const phoneFactor = factor as firebase.auth.PhoneMultiFactorInfo;
          this.enrolledPhoneNumber = phoneFactor.phoneNumber;
          break;
        }
      }
    }
  }

  public promptResetMfa(): void {
    this.router.navigate(['/reauthenticate'], { queryParams: { action: 'reset-mfa' } });
  }
}
