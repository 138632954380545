import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {

  public user$ = this.userService.getCurrentUser();

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
  ) { }

  public ngOnInit(): void {
  }

  public signOut(): void {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

}
