import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ICompany } from '../../../../shared/model/company.model';
import { CompanyService } from '../../../../shared/services/company.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.page.html',
  styleUrls: ['./create.page.scss']
})
export class CreatePage {

  public form = new FormGroup({
    name: new FormControl('', Validators.required),
  })

  constructor(
    private companyService: CompanyService,
    private router: Router,
  ) { }

  public submit(): void {

    this.form.get('name')?.markAsTouched();

    if (this.form.valid) {
      const company = this.parseCompany();
      this.companyService.create(company).then((createdCompany) => {
        this.router.navigate(['/companies', createdCompany.id]);
      });
    }
  }

  private parseCompany(): ICompany {
    return {
      name: this.form.get('name')?.value,
      numberOfBankAccounts: 0,
      deleted: false,
    }
  }

}
