<ng-container *ngIf="(linkToken$ | async) as linkToken; else loading">

  <form [formGroup]="form">

    <app-connect-button
      connectText="Connect to Plaid app"
      [connected]="(connected$ | async)"
      (connect)="linkAccount(linkToken)"
      (disconnect)="disconnect()"
    ></app-connect-button>

    <div>
      <mat-form-field appearance="outline">
        <mat-label>Start date</mat-label>
          <input type="date" matNativeControl formControlName="startDate" />
      </mat-form-field>
    </div>

    <div class="buttons">
      <button [disabled]="!form.valid" mat-flat-button color="primary" (click)="save()">Save</button>
      <button mat-stroked-button [routerLink]="['/bank-accounts', bankAccount.id]">Cancel</button>
    </div>

  </form>

</ng-container>

<ng-template #loading>
  <app-spinner></app-spinner>
</ng-template>
