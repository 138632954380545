import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IBankAccount } from '../../../../shared/model/bank-account.model';
import { BankAccountService } from '../../../../shared/services/bank-account.service';
import { IImportSettingsCmbSftp } from './import-settings-cmb-sftp.model';
import { ImportSettingsCmbSftpService } from './import-settings-cmb-sftp.service';

@Component({
  selector: 'app-import-settings-cmb-sftp',
  templateUrl: './import-settings-cmb-sftp.component.html',
  styleUrls: ['./import-settings-cmb-sftp.component.scss']
})
export class ImportSettingsCmbSftpComponent implements OnInit {

  @Input()
  public bankAccount!: IBankAccount;

  public form = new FormGroup({
    host: new FormControl('', Validators.required),
    port: new FormControl('', Validators.required),
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    folder: new FormControl({ value: '', disabled: true }, Validators.required),
  });

  constructor(
    private bankAccountService: BankAccountService,
    private importSettingsCmbSftpService: ImportSettingsCmbSftpService,
    private router: Router,
  ) { }

  public ngOnInit(): void {
    this.importSettingsCmbSftpService.get(this.bankAccount.id).subscribe((settings) => {
      this.form.patchValue(settings);
    });
  }

  public save(): void {
    if (this.form.valid) {
      const settings = this.parseSettings();
      const bankAccountId = this.bankAccount.id;
      const saveBankAccount = this.bankAccountService.update(this.bankAccount);
      const saveSettings = this.importSettingsCmbSftpService.save(bankAccountId, settings);
      Promise.all([
        saveBankAccount,
        saveSettings,
      ]).then(() => {
        this.router.navigate(['/bank-accounts', bankAccountId]);
      });
    }
  }

  private parseSettings(): IImportSettingsCmbSftp {
    return {
      host: this.form.get('host')?.value,
      port: this.form.get('port')?.value,
      username: this.form.get('username')?.value,
      password: this.form.get('password')?.value,
      folder: this.form.get('folder')?.value,
    };
  }

}
