<ng-container *ngIf="linked || connected; else linkOrConnectTemplate">
  <ng-container *ngIf="connected; else linkedTemplate">
    <div class="text-center" class="connect">
      <div class="connect-button clickable-item" (click)="connect()">
        <mat-icon class="icon" matSuffix svgIcon="lock"></mat-icon>
        Connected to Exact Online
        <div
          class="status active"
          [matTooltip]="'Connected'"
        ></div>
      </div>
      <div *ngIf="connected" class="link">
        <a (click)="disconnect()">Disconnect Exact Online</a>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #linkOrConnectTemplate>
  <ng-container *ngIf="connectedBankAccount$ | async as connectedBankAccount; else connectTemplate">

    <div class="text-center" class="connect">
      <div class="connect-button clickable-item" (click)="link(connectedBankAccount.id)">
        <mat-icon class="icon" matSuffix svgIcon="lock"></mat-icon>
        Link to Exact Online
        <div
          class="status inactive"
          [matTooltip]="'Not connected'"
        ></div>
      </div>
    </div>

  </ng-container>
  
  <div class="cancel text-center">
    <button mat-stroked-button (click)="cancel()">Cancel</button>
    <div class="comment">Don't use Exact Online as my export type</div>
  </div>

</ng-template>
  
<ng-template #connectTemplate>
  <div class="text-center" class="connect">
    <div class="connect-button clickable-item" (click)="connect()">
      <mat-icon class="icon" matSuffix svgIcon="lock"></mat-icon>
      Connect to Exact Online
      <div
        class="status inactive"
        [matTooltip]="'Not connected'"
      ></div>
    </div>
  </div>
</ng-template>

<ng-template #linkedTemplate>
  <div class="text-center" class="connect">
    <div class="connect-button">
      <mat-icon class="icon" matSuffix svgIcon="info"></mat-icon>
      Link has been set up
      <div class="status active"></div>
    </div>
    <div class="link">
      <a (click)="unlink()">Unlink bank account</a>
    </div>
  </div>
</ng-template>

<app-alert type="error" *ngIf="error">{{ error }}</app-alert>

<form [formGroup]="form" *ngIf="connected || linked">
  <div>

    <ng-container *ngIf="divisions$ | async as divisions; else loadingDivisionsTemplate">
      <mat-form-field appearance="outline">
        <mat-label>Administration</mat-label>
        <select
          
          (change)="updateDivision()"
          matNativeControl
          formControlName="division"
        >
          <option value="">-- Select Administration --</option>
          <option *ngFor="let division of divisions" [value]="division.Code">{{ division.Code }} - {{ division.Description }}</option>
        </select>
        <mat-icon matSuffix svgIcon="company"></mat-icon>
      </mat-form-field>
    </ng-container>
  </div>
  <div>
    <ng-container *ngIf="journals$ | async as journals; else loadingJournalsTemplate">
      <mat-form-field appearance="outline">
        <mat-label>Journal</mat-label>
        <select
          matNativeControl
          formControlName="journalCode"
        >
          <option value="">-- Select Journal --</option>
          <option *ngFor="let journal of journals" [value]="journal.Code">{{ journal.Code }} - {{ journal.Description }}</option>
        </select>
        <mat-icon matSuffix svgIcon="company"></mat-icon>
      </mat-form-field>
    </ng-container>
  </div>
  <div>
    <ng-container *ngIf="glAccounts$ | async as glAccounts; else loadingGlAccountsTemplate">
      <mat-form-field appearance="outline">
        <mat-label>GL Account</mat-label>
        <select
          matNativeControl
          formControlName="glAccountId"
        >
          <option value="">-- Select GL Account</option>
          <option *ngFor="let glAccount of glAccounts" [value]="glAccount.ID">{{ glAccount.Code }} - {{ glAccount.Description }}</option>
        </select>
        <mat-icon matSuffix svgIcon="company"></mat-icon>
      </mat-form-field>
    </ng-container>
  </div>
  <div class="buttons">
    <button mat-flat-button color="primary" (click)="save()">Save</button>
    <button mat-stroked-button [routerLink]="['/bank-accounts', bankAccount.id]">Cancel</button>
  </div>
</form>

<ng-template #loadingDivisionsTemplate>
  <div class="loading">Loading Administrations...</div>
</ng-template>

<ng-template #loadingJournalsTemplate>
  <div class="loading">Loading Journals...</div>
</ng-template>

<ng-template #loadingGlAccountsTemplate>
  <div class="loading">Loading GL Accounts...</div>
</ng-template>
