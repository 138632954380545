<app-container [authenticationRequired]="true">
  <app-content-block cssStyle="form">
    <!-- Reauthentication Form -->
    <form *ngIf="!verificationId" [formGroup]="reauthForm" (ngSubmit)="reauthenticate()">
      <h2>Confirm Your Password</h2>
      <p *ngIf="action=='reset-mfa'">For your security, we need to confirm your identity before allowing you to reset MFA.</p>
      <p *ngIf="action=='enroll-mfa'">For your security, we need to confirm your identity before allowing you to set up MFA</p>
      <mat-error *ngIf="errorMessage">{{ errorMessage }}</mat-error>
      <div>
        <mat-form-field class="full-width-input" appearance="outline">
          <mat-label>Password</mat-label>
          <input
            matInput
            type="password"
            formControlName="password"
            autocomplete="current-password"
          />
          <mat-error>Please enter your password</mat-error>
        </mat-form-field>
      </div>
      <button mat-flat-button color="primary">Continue</button>
    </form>

    <!-- MFA Verification Form -->
    <form *ngIf="verificationId" [formGroup]="verificationForm" (ngSubmit)="verifyCode()">
      <h2>Multi-Factor Authentication</h2>
      <p>A verification code has been sent to your phone. Please enter it below:</p>
      <mat-error *ngIf="verificationErrorMessage">{{ verificationErrorMessage }}</mat-error>
      <div>
        <mat-form-field class="full-width-input" appearance="outline">
          <mat-label>Verification Code</mat-label>
          <input
            matInput
            formControlName="verificationCode"
            autocomplete="one-time-code"
          />
          <mat-error>Please enter the verification code</mat-error>
        </mat-form-field>
      </div>
      <button mat-flat-button color="primary">Verify Code</button>
      <div class="account-link-div">
        <ul>
          <li><a (click)="resendCode()" class="clickable-link">Resend Verification Code</a></li>
        </ul>
      </div>
    </form>
  </app-content-block>
</app-container>
